.btn {
    display: inline-flex; align-items: center;
    padding: .9rem 1rem;
    font-size: 1rem;
    font-weight: 500; line-height: 1;
    text-decoration: none;
    cursor: pointer;
    transition: all .2s ease-in-out;
    border: none !important; border-radius: 5px !important;
    background: $neon !important; color: #fff !important;
    &::after {
        content: '\e000';
        font-family: 'Font Awesome Kit';
        font-size: 1.55rem;
        padding: 0 .75rem;
        transition: all .2s;
        display: inline-block;
    }

    &.arrow-back {
        &::after { content: none; }
        &::before {
            content: '\e000';
            transform: scale(-1);
            font-family: 'Font Awesome Kit';
            font-size: 1.55rem;
            padding: 0 .75rem;
            transition: all .2s;
            display: inline-block;
        }
    }


    &.no-icon {
        &::before, &::after {
            color: transparent;
            width: 0px !important;
            padding: 0 !important; margin: 0 !important;
        }

        span {
            margin-right: .5rem !important;
        }
    }

    &:hover {
        background: darken($neon, 20%) !important; color: #fff !important;
        &::before { padding: 0 0 0 1.5rem; }
        &::after { padding: 0 0 0 1.5rem; }
    }


    &.dark {
        background: #000 !important;
        color: #fff !important;
        &:hover {
            background: #1c1b20 !important;
            color: mix($neon, #fff) !important;
        }
    }

    &.outline {
        background: transparent !important; color: #fff !important;
        border: 1px solid #fff !important;
        &:hover { border-color: #d5d5d5 !important; color: #d5d5d5 !important; }
    }

    &.white {
        background: #f2f2f2 !important;  color: #1c1b20 !important;
        &:hover { background: #d5d5d5 !important; color: #1c1b20 !important; }

        &.outline {
            background: transparent !important; color: #1c1b20 !important;
            border: 1px solid #1c1b20 !important;
            &:hover { border-color: #333 !important; color: #333 !important; }
        }
    }

    &.dark {
        background: #000 !important;
        color: #fff !important;

        &:hover {
            background: #1c1b20 !important;
            color: mix($neon, #fff) !important;
        }
    }
}


.__animated-btn {
    font-size: 1rem; color: #FFFFFF;
    background: $neon;
    border: none; border-radius: 0;
    transition: all 0.3s ease-in-out 0s;
    cursor: pointer;
    outline: none;
    position: relative; z-index: 2;
    padding: 1rem 1.5rem;
    text-decoration: none !important;
    font-weight: 600;
    display: inline-block;

    &.sm {
        padding: .7rem .9rem !important;
        font-size: 0.8rem !important;
    }
}
.__animated-btn:hover, .button:focus {
    color: #313133;
    transform: translateX(16px);
}
button:focus::before {
    opacity: 1;
}
.__animated-btn.pulse::after {
    content: '';
    width: 20px; height: 20px;
    border-radius: 100%;
    border: 6px solid #00FFCB;
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ring 1.5s infinite alternate;
}
.__animated-btn:hover::after, button:focus::after {
    animation: none;
    display: none;
}
@keyframes ring {
    0% {
        width: 30px;
        height: 30px;
        opacity: 1;
    }
    100% {
        width: 120px;
        height: 120px;
        opacity: 0;
    }
}
