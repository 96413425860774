.__form-parent {
    position: fixed;
    top: 0; right: 0; bottom: 0;
    width: 50vw;

    transform: translateX(100%);
    transition: all .2s ease-in-out;
    background: rgba(0,0,0, .65);
    color: #fff;
    backdrop-filter: blur(20px);
    z-index: 9999;
    overflow-y: scroll;

    .__close {
        position: absolute;
        top: 0; right: 0;
        padding: 1rem;
        cursor: pointer;

        i {
            font-size: 2rem;
        }
    }

    &.large {
        width: 100vw !important;

    }

    @media (max-width: $breakpoint-lg) {
        width: 100vw !important;
    }

    &.active {
        transform: translateX(0);
        opacity: 1; pointer-events: auto;
    }

    .alert {
        li {
            font-size: .95rem !important;
        }
        ol {
            list-style: circle;
        }
    }

    .btn.loading {
        pointer-events: none !important;
        cursor: not-allowed;
        &::after {
            display: inline-block;
            content: '';
            width: 1.55rem; height: 1.55rem;
            border: 2px solid #fff;
            border-top-color: transparent;
            border-radius: 50%;
            animation: spin 1s linear infinite;
            padding: 0 !important;
            margin-left: .5rem;
        }
    }
}

html:has(.__form-parent.active) {
    overflow: hidden;
    body {
        overflow: hidden;
    }
}

body:has(.__form-parent.active) {
    .intercom-lightweight-app-launcher {
        display: none !important;
        visibility: hidden !important;
    }
}

body:has(.__form-parent) {
    .select2-dropdown {
        z-index: 10001 !important;
    }
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.--parent-big-icons {
    li {
        margin-bottom: 1rem;
    }
    i {
        width: 25px;
        font-size: 1rem;
        color: $neon;
    }
}