//|---------------------------------------------------------|//
//| Case panel section (upper one)
.cases__parent {
    .grid {
        display: grid;
        grid-gap: 0 30px;
        grid-template-columns: repeat(2, 1fr);
        @media (max-width: $breakpoint-md) {
            grid-template-columns: 1fr;
        }

        .case-panel {
            @media (min-width: calc($breakpoint-sm + 1px) ) {
                > a {
                    display: flex !important;
                    flex-direction: column;
                    height: 100%;
                    justify-content: space-between;

                    .__readmore-btn {
                        align-self: baseline;
                    }
                }
            }
            img {
                aspect-ratio: 16/9;
                object-fit: cover;
            }
            @media (min-width: calc($breakpoint-md + 1px)) {
                &:nth-of-type(5) {
                    grid-column: 1/3;
                    text-align: center;
                    .__readmore-btn {
                        align-self: center !important;
                    }

                    img:hover {
                        transform: scale(1) !important;
                    }
                }
            }
        }
    }
}

//|---------------------------------------------------------|//
//| Case panel section (lower one)
.cases-small__parent {
    padding-top: 100px;
    @media (max-width: $breakpoint-lg) {
        padding-top: 50px;
    }

    .grid {
        display: grid;
        grid-gap: 0 30px;
        grid-template-columns: repeat(2, 1fr);
        @media (max-width: $breakpoint-md) {
            grid-template-columns: 1fr;
        }

        .case-panel {

            @media (min-width: calc($breakpoint-sm + 1px) ) {
                > a {
                    display: flex !important;
                    flex-direction: column;
                    height: 100%;
                    justify-content: space-between;

                    .__readmore-btn {
                        align-self: baseline;
                    }
                }
            }

            img {
                aspect-ratio: 16/9;
                object-fit: cover;
            }

            @media (min-width: calc($breakpoint-md + 1px)) {
                &:nth-of-type(3) {
                    grid-column: 1/3;
                    text-align: center;

                    .__readmore-btn {
                        align-self: center !important;
                    }

                    img:hover {
                        transform: scale(1) !important;
                    }
                }
            }
        }
    }
}

//|---------------------------------------------------------|//
//| CASE PANEL
.case-panel {
    @media (min-width: calc($breakpoint-sm + 1px)) {
        margin-bottom: 4rem !important;
        height: calc(100% - 4rem) !important;

        > a {
            display: flex !important;
            flex-direction: column;
            justify-content: space-between;
            height: 100% !important;

            .__readmore-btn {
                align-self: baseline;
            }
        }
    }

    figure {
        overflow: hidden;
        img {
            transition: all .2s ease-in-out;
        }
    }
    &:hover {
        img {
            transform: scale(1.05);
        }
    }
}