
section.let-us-help {
    .suggestion {
        @media (max-width: $breakpoint-lg) {
            display: none;
        }
        img {
            position: absolute; left: 210px; top: -60px;
            pointer-events: none !important;
            width: 300px;
            @media (min-width: 1340px) {
                max-width: none;
            }
            @media (max-width: 1080px) {
                left: 0; top: 20px;
                transform: translateY(-100%);
            }
        }
    }
}

section.hero {
    @media (max-width: $breakpoint-md) {
        padding-top: 10rem !important;
        min-height: 0 !important;
    }
}

.hero-slider .slick-dots {
    height: 4px !important;
    opacity: 0;
    transition: opacity .2s ease-in-out;
    transition-delay: .25s;
}



.hero-slider {
    .__image-holder:has(iframe) {
        position: relative;
        &::before {
            content: '';
            position: absolute; top: 0; left: 0; right: 0; bottom: 0;
            background-image: url('/img/play.png');
            background-size: 5rem 5rem;
            background-position: center center;
            background-repeat: no-repeat;
            opacity: .5;
            z-index: 10; transition: all .2s ease-in-out;
            pointer-events: none !important;
        }
        &:is(:hover, :focus, :focus-within) {
            &::before {
                opacity: 0 !important;
            }
        }
    }
}