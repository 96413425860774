body.service-detail {

    .info-row__content {
        ul {
            margin-top: 2rem;
        }
    }

    .info-row-two {
        h3 {
            color: $blue-color;
        }

        @media (min-width: $breakpoint-lg) {
            p {
                margin-bottom: 45px;
            }
        }
    }

    .info-row-three {
        .row.position-to-top {
            margin-top: -50px;

            @media (min-width: calc($breakpoint-md + 1px)) {
                .image--parent {
                    margin-right: -100px;

                    &.reverse {
                        margin-right: 0;
                        margin-left: -100px;
                    }
                }
            }

            .text--parent {
                background: #000;
                color: #fff;
                padding: 45px;
                position: relative; z-index: 1;
                @media (min-width: calc($breakpoint-lg + 1px)) {
                    margin-top: 50px;
                }

                h3 {
                    color: $blue-color2;
                }

                ul {
                    list-style: none;
                    margin: 0;
                    margin-bottom: 2.5rem;
                    padding: 0;

                    li {
                        margin-bottom: 10px;

                        &::before {
                            content: '';
                            margin-right: 30px;
                            display: inline-block;
                            width: 8px;
                            height: 8px;
                            background: $blue-color2;
                        }
                    }
                }
            }
        }
    }



    section.purple-banner {
        h3 {
            margin: 0 !important;
        }
    }
}