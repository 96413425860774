body.marketing-scan {
    section.subhero {

        .right-side {
            position: relative;
            img {
                border-radius: 50%;
                width: 100%; aspect-ratio: 1;
                position: relative;
                right: 0; top: 0; transform: translate(50%, -50%) scale(1.375);
            }
            .cta {
                position: absolute; left: 30px; top: 0;
                background: $yellow-color; color: #000;
                width: 250px; aspect-ratio: 1;
                border-radius: 50%; padding: 20px;
                text-align: center;
                display: flex; justify-content: center; align-items: center;
                h3 {
                    font-size: 1.65rem;
                    strong { font-size: 3.15rem; }
                }
            }
        }
    }

    section.companys {
        .companys-grid {
            padding: 30px 0;
            display: grid;
            grid-template-columns: repeat(4,1fr);
            gap: 30px;

            a {
                display: flex; justify-content: center; align-items: center;
                height: 100%;
                img {
                    width: 240px;
                    height: 110px;
                    object-fit: contain;
                }
            }
        }
    }

    section.subhero {

        .left-side {
            position: relative;
            img {
                border-radius: 50%;
                width: 100%; aspect-ratio: 1;
                position: relative;
                left: 0; top: 0; transform: translate(-50%, -60%) scale(1.375);
            }
            .cta {
                position: absolute; right: 30px; top: 0;
                background: $yellow-color; color: #000;
                width: 250px; aspect-ratio: 1;
                border-radius: 50%; padding: 20px;
                text-align: center;
                display: flex; justify-content: center; align-items: center;
                h3 {
                    font-size: 1.65rem;
                    strong { font-size: 3.15rem; }
                }
            }
        }
    }

}