$link-color: #dfdfdf;

footer {
    position: relative;
    z-index: 1010;

    .footer-top-block {
        background-image: url('/img/backgrounds/footer.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    h2, h2 a {
        color: #fff !important;
        font-size: 1.1rem;
        font-weight: 700;
        margin: 0 0 1em;
        text-transform: capitalize;
        text-decoration: none !important;
        transition: all .2s ease-in-out;

        &:hover { color: #fff !important; }
        &.active { color: $neon !important; }
    }

    //| global footer <a> styling
    a {
        text-decoration: none;
        transition: all .2s ease-in-out;
        &:hover {
            color: $neon;
        }
    }



    //| menu spacing
    .__menu + .__menu {
        margin-top: 4.5rem;
    }

    ul.footer-menu {
        margin: 0;
        padding: 0;

        li {
            display: block;
            list-style: outside none none;
            margin: 0;
            line-height: 1.3 !important;
            overflow: hidden;
            padding-left: 0;

            a {
                padding: 0.3em 0;
                color: $link-color;
                position: relative;
                font-size: 0.9rem;
                display: block;
                margin: 0;
                text-decoration: none;

                &:has(br) {
                    line-height: 1.8 !important;
                    margin-top: -3px !important;
                }
            }

            a:not(._no-effect) {
                &::after {
                    position: absolute;
                    -webkit-transition: all 0.35s ease;
                    transition: all 0.35s ease;
                    color: $neon;
                    padding: 0.3em 0;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    content: attr(data-hover);
                    white-space: nowrap;
                }

                &.__hover-mw::after {
                    max-width: 160px;
                    white-space: wrap;
                }

                &.active {
                    color: $neon;
                }
            }

            &:hover a:not(._no-effect) {
                transform: translateY(-100%);
            }

            //| Animation style
            * {
                box-sizing: border-box;
                -webkit-transition: all 0.3s ease;
                transition: all 0.3s ease;
            }
        }
    }

    .__socialmedia {
        .social-link {
            color: $link-color;
            font-size: 1.3rem;
            text-decoration: none !important;
            transition: all .2s ease-in-out;

            &:hover {
                color: $neon;
            }
        }
    }


    .nf-seo-pages-menu {
        gap: .2rem .5rem !important;
        margin: 2rem 0 -2rem;
        @media (max-width: $breakpoint-lg) {
            margin: 3.5rem 0 -2rem;
        }
        @media (max-width: $breakpoint-sm) {
            margin: 2.75rem 0 .5rem;
        }
        a, span, p {
            color: #fff;
            @media (max-width: $breakpoint-sm) {
                color: $gray-800;
            }
        }
        a {
            &:hover, &.active {
                color: $neon !important;
            }
        }
    }

    .__connect {
        h1 {
            font-size: 2rem !important;
            font-weight: 600 !important;
        }

        p {
            margin-bottom: .275rem !important;
            font-size: 0.9rem !important;
            color: #dfdfdf;

            strong {
                font-weight: 800;
                color: #fff !important;
            }

            a {
                color: inherit;
            }
        }
    }
}

@media (max-width: $breakpoint-sm) {
    footer {
        position: relative;

        .footer-top-block {
            background: #fff !important;
            > .container {
                padding: 0 1.5rem;
            }

            .__menu {
                h2 {
                    color: $gray-800 !important;
                }
                li, a {
                    font-size: 1.1rem !important;
                }
                a:not(.active) {
                    color: $gray-700 !important;
                }
            }
            p, strong , a{
                color: $gray-700 !important;
            }
        }
    }
}