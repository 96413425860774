section.about-page-block {
    .col-md-6 {
        ul {
            list-style: none;
            margin: 0; padding: 0;

            li {
                &::before {
                    content: '\f178';
                    font-family: "Font Awesome 6 Pro"; font-weight: 300; font-size: 1.2rem;
                    color: $neon;
                    display: inline-block; margin-right: 15px;
                }
                padding: 10px 0;
                transition: all .2s ease-in-out;

                &:hover {
                    padding-left: 25px;
                }
                a {
                    text-decoration: none;
                    color: #000;
                    font-size: 1.5rem;
                    font-weight: 600;
                }
            }
        }
    }
}