/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */
/* NeueHaasDisplay */
@font-face {
    font-family:'NeueHaasDisplayPro';
    src: url('y Black.eot');
	src: url('y Black.eot?#iefix') format('embedded-opentype'),
		url('y Black.woff2') format('woff2'),
		url('y Black.woff') format('woff'),
		url('y Black.ttf') format('truetype'),
		url('y Black.otf') format('opentype'),
		url('y Black.svg#y Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'NeueHaasDisplayPro';
    src: url('y BlackItalic.eot');
	src: url('y BlackItalic.eot?#iefix') format('embedded-opentype'),
		url('y BlackItalic.woff2') format('woff2'),
		url('y BlackItalic.woff') format('woff'),
		url('y BlackItalic.ttf') format('truetype'),
		url('y BlackItalic.otf') format('opentype'),
		url('y BlackItalic.svg#y BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'NeueHaasDisplayPro';
    src: url('y Bold.eot');
	src: url('y Bold.eot?#iefix') format('embedded-opentype'),
		url('y Bold.woff2') format('woff2'),
		url('y Bold.woff') format('woff'),
		url('y Bold.ttf') format('truetype'),
		url('y Bold.otf') format('opentype'),
		url('y Bold.svg#y Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'NeueHaasDisplayPro';
    src: url('y BoldItalic.eot');
	src: url('y BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('y BoldItalic.woff2') format('woff2'),
		url('y BoldItalic.woff') format('woff'),
		url('y BoldItalic.ttf') format('truetype'),
		url('y BoldItalic.otf') format('opentype'),
		url('y BoldItalic.svg#y BoldItalic') format('svg');
    font-weight: 700;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'NeueHaasDisplayPro';
    src: url('y Light.eot');
	src: url('y Light.eot?#iefix') format('embedded-opentype'),
		url('y Light.woff2') format('woff2'),
		url('y Light.woff') format('woff'),
		url('y Light.ttf') format('truetype'),
		url('y Light.otf') format('opentype'),
		url('y Light.svg#y Light') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'NeueHaasDisplayPro';
    src: url('y LightItalic.eot');
	src: url('y LightItalic.eot?#iefix') format('embedded-opentype'),
		url('y LightItalic.woff2') format('woff2'),
		url('y LightItalic.woff') format('woff'),
		url('y LightItalic.ttf') format('truetype'),
		url('y LightItalic.otf') format('opentype'),
		url('y LightItalic.svg#y LightItalic') format('svg');
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'NeueHaasDisplayPro';
    src: url('y Mediu.eot');
	src: url('y Mediu.eot?#iefix') format('embedded-opentype'),
		url('y Mediu.woff2') format('woff2'),
		url('y Mediu.woff') format('woff'),
		url('y Mediu.ttf') format('truetype'),
		url('y Mediu.otf') format('opentype'),
		url('y Mediu.svg#y Mediu') format('svg');
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'NeueHaasDisplayPro';
    src: url('y MediumItalic.eot');
	src: url('y MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('y MediumItalic.woff2') format('woff2'),
		url('y MediumItalic.woff') format('woff'),
		url('y MediumItalic.ttf') format('truetype'),
		url('y MediumItalic.otf') format('opentype'),
		url('y MediumItalic.svg#y MediumItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'NeueHaasDisplayPro';
    src: url('y Roman.eot');
	src: url('y Roman.eot?#iefix') format('embedded-opentype'),
		url('y Roman.woff2') format('woff2'),
		url('y Roman.woff') format('woff'),
		url('y Roman.ttf') format('truetype'),
		url('y Roman.otf') format('opentype'),
		url('y Roman.svg#y Roman') format('svg');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'NeueHaasDisplayPro';
    src: url('y RomanItalic.eot');
	src: url('y RomanItalic.eot?#iefix') format('embedded-opentype'),
		url('y RomanItalic.woff2') format('woff2'),
		url('y RomanItalic.woff') format('woff'),
		url('y RomanItalic.ttf') format('truetype'),
		url('y RomanItalic.otf') format('opentype'),
		url('y RomanItalic.svg#y RomanItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'NeueHaasDisplayPro';
    src: url('y Thin.eot');
	src: url('y Thin.eot?#iefix') format('embedded-opentype'),
		url('y Thin.woff2') format('woff2'),
		url('y Thin.woff') format('woff'),
		url('y Thin.ttf') format('truetype'),
		url('y Thin.otf') format('opentype'),
		url('y Thin.svg#y Thin') format('svg');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'NeueHaasDisplayPro';
    src: url('y ThinItalic.eot');
	src: url('y ThinItalic.eot?#iefix') format('embedded-opentype'),
		url('y ThinItalic.woff2') format('woff2'),
		url('y ThinItalic.woff') format('woff'),
		url('y ThinItalic.ttf') format('truetype'),
		url('y ThinItalic.otf') format('opentype'),
		url('y ThinItalic.svg#y ThinItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'NeueHaasDisplayPro';
    src: url('y XThin.eot');
	src: url('y XThin.eot?#iefix') format('embedded-opentype'),
		url('y XThin.woff2') format('woff2'),
		url('y XThin.woff') format('woff'),
		url('y XThin.ttf') format('truetype'),
		url('y XThin.otf') format('opentype'),
		url('y XThin.svg#y XThin') format('svg');
    font-weight: 200;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'NeueHaasDisplayPro';
    src: url('y XThinItalic.eot');
	src: url('y XThinItalic.eot?#iefix') format('embedded-opentype'),
		url('y XThinItalic.woff2') format('woff2'),
		url('y XThinItalic.woff') format('woff'),
		url('y XThinItalic.ttf') format('truetype'),
		url('y XThinItalic.otf') format('opentype'),
		url('y XThinItalic.svg#y XThinItalic') format('svg');
    font-weight: 200;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'NeueHaasDisplayPro';
    src: url('y XXThin.eot');
	src: url('y XXThin.eot?#iefix') format('embedded-opentype'),
		url('y XXThin.woff2') format('woff2'),
		url('y XXThin.woff') format('woff'),
		url('y XXThin.ttf') format('truetype'),
		url('y XXThin.otf') format('opentype'),
		url('y XXThin.svg#y XXThin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'NeueHaasDisplayPro';
    src: url('y XXThinItalic.eot');
	src: url('y XXThinItalic.eot?#iefix') format('embedded-opentype'),
		url('y XXThinItalic.woff2') format('woff2'),
		url('y XXThinItalic.woff') format('woff'),
		url('y XXThinItalic.ttf') format('truetype'),
		url('y XXThinItalic.otf') format('opentype'),
		url('y XXThinItalic.svg#y XXThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}