header {
    @media (max-width: $breakpoint-md) {
        padding: 15px 0 !important;
    }
    #logo {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        img {
            width: 300px;
            max-width: 100%;
            transition: all .2s ease-in-out;
            &:hover {
                transform: scale(1.05);
            }

            @media (max-width: $breakpoint-md) {
                width: 200px;
            }
        }
    }
    img {
        transition: all .2s ease-in-out;
    }
    &.sticky {
        img {
            width: 200px !important;
            @media (max-width: $breakpoint-lg) {
                width: 175px !important;
            }
            @media (max-width: $breakpoint-md) {
                width: 150px !important;
            }
        }
    }
}
.main-nav {
    ul{
        padding-left: 0 !important;
    }
    li {
        padding-left: 0 !important;
        a {
            cursor: pointer;
            &:is(.active, :hover) {
                color: $neon !important;
            }

            i {
                width: 16px !important; height: 16px !important;
                font-size: .7rem !important;
                display: flex !important; align-items: center !important; justify-content: center !important;
            }
        }
    }

    @media (max-width: 1023px) {
        li {
            a {
                &:has(i) {
                    position: relative;
                    i {
                        position: absolute;
                        left: 62px; top: 5px;
                    }
                }
            }
            &:last-of-type {
                border-bottom: none !important;
            }
        }
    }


    @media (min-width: calc($breakpoint-lg + 1px)) {
        .__mobile-logo {
            display: none !important; visibility: hidden !important;
            pointer-events: none !important; opacity: 0 !important;
        }
        li {
            display: inline-block;
            list-style: outside none none;
            margin: 0;
            overflow: hidden;

            a {
                padding: 0.3em 0;
                color: rgba(255, 255, 255, 0.5);
                position: relative;
                display: inline-block;
                margin: 0;
                text-decoration: none;
                &::after {
                    position: absolute;
                    -webkit-transition: all 0.35s ease;
                    transition: all 0.35s ease;
                    color: $neon;
                    padding: 0.3em 0;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    content: attr(data-hover);
                    white-space: nowrap;
                }
                &.active {
                    color: $neon;
                }
            }

            &:hover a {
                transform: translateY(-100%);
            }

            * {
                box-sizing: border-box;
                -webkit-transition: all 0.35s ease;
                transition: all 0.35s ease;
            }
        }
    }
    @media (max-width: $breakpoint-lg) {
        .__mobile-logo {
            position: absolute; bottom: 0; left: 0;
            z-index: 0;
            width: 100%;
            padding: 2rem 0 6rem;
        }
        &> ul {
            max-height: calc(100% - 8rem);
            position: relative; z-index: 1;
        }
    }
}

