section.subhero {
    padding-bottom: 75px !important;
    opacity: 0;
    animation: fadein .2s ease-in-out forwards;
    animation-delay: .2s;
    @media (max-width: $breakpoint-lg) {
        padding-bottom: 60px !important;
    }
}
//--------------
// Slider
.subhero_slider, .hero-slider, .case_slider {
    opacity: 0;
    animation: fadein .5s ease-in-out forwards;
    animation-delay: .5s;

    .slick-arrow {
        z-index: 9999;
        width: 2.5rem; height: 2.5rem;
        background: $neon; box-shadow: 0 0 .5rem rgba(0,0,0,.025);
        color: #fff;
        display: flex; align-items: center; justify-content: center;
        &::before { content: none !important; }

        i { font-size: 1.5rem; }
    }
    @media (max-width: 1100px) {
        .slick-next { right: .25rem; }
        .slick-prev { left: .25rem; }
    }

    .slick-list {
        overflow: visible !important;

        .slick-slide {
            margin: 0 20px;
            @media (max-width: $breakpoint-lg) {
                margin: 0 .5rem;
            }
        }
    }

    .__image-holder {
        aspect-ratio: 16/9;
        position: relative;
    }

    img, iframe {
        width: 100%;
        aspect-ratio: 16/9;
        height: 100%;
        object-fit: cover;
        position: relative; z-index: 1;
        transition: all .2s ease-in-out;

        &.__layer {
            position: absolute !important; top: 0; left: 0; right: 0; bottom: 0;
            width: 100%; height: 100%;
            object-fit: cover;
            z-index: 2; opacity: 0;
            transition: all .2s ease-in-out;
            &:is(:hover, :focus, :focus-within) { opacity: 1; }
        }
    }

    .slick-dots {
        display: grid !important; 
        width: 90% !important;
        position: relative !important;
        margin: auto !important;
        list-style: none !important;
        gap: 8px; 
        li {
            width: 100%; height: 4px;
            padding: 0 !important;
            button {
                width: 100%; height: 8px; padding: 0 !important;
                background: #f2f2f2;
                &::before {
                    content: none !important;
                }
            }
            &.slick-active, &:hover {
                button {
                    background: $neon !important
                }
            }
        }
    }
}

@keyframes fadein {
    from    { opacity: 0; }
    to {
        opacity: 1;
    }
}