//|---------------------------------------------------------|//
//| Services on homepage
section.services {
    .title {
        h2 ~ * {
            font-weight: 500;
        }
    }
    a {
        text-decoration: none !important; color: inherit;
        * {
            text-decoration: none !important; color: inherit;
        }
        .item {
            padding: 1.5rem 0 !important;
            transition: all .2s ease-in-out;
            border-bottom: 1px solid #bdbdbd;
            width: 100%;
            &:hover { * { color: #5ddfa7; } }

            h3 {
                font-size: 1.5rem;
                line-height: 1.1;
                transition: all .2s ease-in-out;
                margin: 0 !important;
            }
        }
    }
}

//|---------------------------------------------------------|//
//| Services on /services page
.services__parent:last-of-type {
    padding: 4.5rem 0 1rem;
}
