section.error-part {
    background-position: center;
    background-size: cover;

    @media (max-width: $breakpoint-md) {
        background: #000;
        background-image: none !important;
    }

    * {
        color: #fff;
    }
}