@import 'fonts/HelveticaNeue/fonts.css';
@import 'fonts/NeueHaasDisplay/fonts.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400&display=swap');

body {
    font-weight: 300;
    color: #000000;
}

@each $type, $size, $line-height, $weight in $text-sizing {
    #{$type} {
        font-size: $size;
        line-height: 1.1;
        font-weight: $weight;
    }
    #{$type}.larger {
        font-size: $size * 1.5;
    }
    @media (max-width: $breakpoint-lg) {
        #{$type}        { font-size: $size * .95; }
        #{$type}.larger { font-size: $size * 1.1; }
    }
    @media (max-width: $breakpoint-md) {
        #{$type}        { font-size: $size * .9; }
        #{$type}.larger { font-size: $size * .975; }
    }
}

@media (max-width: $breakpoint-md) {
    h1 { font-size: 2.65rem; }
    h2 { font-size: 2.1rem; }
}

main, footer {
    p, li {
        font-family: 'Inter', sans-serif;
        font-size: 1.4rem;
        font-weight: 300;
        line-height: 1.6 !important;
        letter-spacing: -.02rem;

        @media (max-width: $breakpoint-lg) {
            font-size: 1.3rem;
        }
        @media (max-width: $breakpoint-md) {
            font-size: 1.2rem;
        }
        @media (max-width: $breakpoint-sm) {
            font-size: 1.1rem;
        }
    }
}

.text-yellow { color: $yellow-color !important; }
.text-purple { color: $purple-color !important; }
.text-neon  { color: $neon !important; }
.text-pink  { color: $pink-color !important; }

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
    margin-bottom: 2.5rem;
    margin-top: -.35rem;
    font-weight: bold;
    @media (max-width: $breakpoint-lg) {
        margin-bottom: 2.2rem;
    }
    @media (max-width: $breakpoint-md) {
        margin-bottom: 1.85rem;
    }
    @media (max-width: $breakpoint-sm) {
        margin-bottom: 1.7rem;
    }
}