section.text-image {

    &.--text-with-beam {
        &:has(.__beam) {
            @media (min-width: $breakpoint-lg) {
                margin-bottom: 100px;
                margin-top: -50px;
            }
        }
    }
}