/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'HelveticaNeue';
    src: url('Helvetica Neue Bold.eot');
	src: url('Helvetica Neue Bold.eot?#iefix') format('embedded-opentype'),
		url('Helvetica Neue Bold.woff2') format('woff2'),
		url('Helvetica Neue Bold.woff') format('woff'),
		url('Helvetica Neue Bold.ttf') format('truetype'),
		url('Helvetica Neue Bold.otf') format('opentype'),
		url('Helvetica Neue Bold.svg#Helvetica Neue Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0-10FFFF;
}

@font-face {
    font-family:'HelveticaNeue';
    src: url('Helvetica Neue Light.eot');
	src: url('Helvetica Neue Light.eot?#iefix') format('embedded-opentype'),
		url('Helvetica Neue Light.woff2') format('woff2'),
		url('Helvetica Neue Light.woff') format('woff'),
		url('Helvetica Neue Light.ttf') format('truetype'),
		url('Helvetica Neue Light.otf') format('opentype'),
		url('Helvetica Neue Light.svg#Helvetica Neue Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0-10FFFF;
}

@font-face {
    font-family:'HelveticaNeue';
    src: url('Helvetica Neue Medium.eot');
	src: url('Helvetica Neue Medium.eot?#iefix') format('embedded-opentype'),
		url('Helvetica Neue Medium.woff2') format('woff2'),
		url('Helvetica Neue Medium.woff') format('woff'),
		url('Helvetica Neue Medium.ttf') format('truetype'),
		url('Helvetica Neue Medium.otf') format('opentype'),
		url('Helvetica Neue Medium.svg#Helvetica Neue Medium') format('svg');
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0-10FFFF;
}

@font-face {
    font-family:'HelveticaNeue';
    src: url('Helvetica Neue.eot');
	src: url('Helvetica Neue.eot?#iefix') format('embedded-opentype'),
		url('Helvetica Neue.woff2') format('woff2'),
		url('Helvetica Neue.woff') format('woff'),
		url('Helvetica Neue.ttf') format('truetype'),
		url('Helvetica Neue.otf') format('opentype'),
		url('Helvetica Neue.svg#Helvetica Neue') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0-10FFFF;
}

@font-face {
    font-family:'HelveticaNeue';
    src: url('Helvetica Neue Italic.eot');
	src: url('Helvetica Neue Italic.eot?#iefix') format('embedded-opentype'),
		url('Helvetica Neue Italic.woff2') format('woff2'),
		url('Helvetica Neue Italic.woff') format('woff'),
		url('Helvetica Neue Italic.ttf') format('truetype'),
		url('Helvetica Neue Italic.otf') format('opentype'),
		url('Helvetica Neue Italic.svg#Helvetica Neue Italic') format('svg');
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0-10FFFF;
}

@font-face {
    font-family:'HelveticaNeue';
    src: url('HelveticaNeue UltraLight.eot');
	src: url('HelveticaNeue UltraLight.eot?#iefix') format('embedded-opentype'),
		url('HelveticaNeue UltraLight.woff2') format('woff2'),
		url('HelveticaNeue UltraLight.woff') format('woff'),
		url('HelveticaNeue UltraLight.ttf') format('truetype'),
		url('HelveticaNeue UltraLight.otf') format('opentype'),
		url('HelveticaNeue UltraLight.svg#HelveticaNeue UltraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FC;
}

@font-face {
    font-family:'HelveticaNeue';
    src: url('HelveticaNeue-Thin.eot');
	src: url('HelveticaNeue-Thin.eot?#iefix') format('embedded-opentype'),
		url('HelveticaNeue-Thin.woff2') format('woff2'),
		url('HelveticaNeue-Thin.woff') format('woff'),
		url('HelveticaNeue-Thin.ttf') format('truetype'),
		url('HelveticaNeue-Thin.otf') format('opentype'),
		url('HelveticaNeue-Thin.svg#HelveticaNeue-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}