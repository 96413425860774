html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;

    &.locked {
        overflow: hidden !important;
    }
}

* {
    padding: 0;
    margin: 0;
}

//Set max container width same as design.
.container { max-width: 1030px !important; }


.bg-purple {
    background: $purple-color;
}

.bg-yellow {
    background: $yellow-color;
}


ul {
    li::marker {
       color: $neon;
    }
    li {
        padding-left: 1rem;
    }
}

ul.white {
    li {
        color: #fff;
    }
}

ol, ul {
    padding-left: 1.45rem;
}

.check-list ul {
    padding-left: 1.25rem !important;
    li {
        padding-left: 2rem !important;
        margin-bottom: 7px;
    }
}

.grecaptcha-badge {
    display: none !important;
    visibility: hidden !important;
    opacity: 0 !important;
    pointer-events: none !important;
}

//| Fix for mobile devices
.slick-dots li button {
    padding: 0 !important;
}

