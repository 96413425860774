.__whatsapp {
    text-decoration: none !important;
    position: fixed; bottom: 20px; right: 20px; z-index: 99999;
    border: none; border-radius: 50%;
    transition: all .2s ease-in-out;
    animation: pulse 10s infinite;
    cursor: pointer;
    img {
        width: 50px;
        height: 50px;
    }

    &:hover { transform: scale(1.1); }
}

@keyframes pulse {
    0%, 90% {
        box-shadow: limegreen 0 0 0 0px;
    }
    100% {
        box-shadow: transparent 0 0 0 10px;
    }
}