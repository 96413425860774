section.cta-w-video {
    &.online-marketing {
        @media (min-width: $breakpoint-md) {
            margin-bottom: 100px;
        }
        @media (max-width: $breakpoint-sm) {
            .crazyproject-block {
                padding: 2rem 0 !important;
            }
        }
    }
}