@keyframes fadeFromBottom {
    0% {
        opacity: 0;
        transform: translateY(200px) scale(.9);
    }
    100% {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}


.__animate-fadeFromBottom {
    animation: fadeFromBottom .375s ease-in-out forwards;
    position: relative; opacity: 0;
}