section.lets-work-together {
    padding: 5rem 0;
    @media (max-width: $breakpoint-md) {
        padding: 4rem 0;
    }
    @media (max-width: $breakpoint-sm) {
        padding: 1rem 0;
    }

    .-child {
        background: #000;
        background-image: url('/img/backgrounds/cta.jpg');
        background-size: cover; background-repeat: no-repeat;
        background-position: center center;
        position: relative;
        padding: 100px 70px;
        @media (max-width: $breakpoint-md) {
            padding: 60px 30px;
        }
        @media (max-width: $breakpoint-sm) {
            padding: 45px 20px;
        }
        color: #fff !important;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        box-shadow: 0 0 15px rgba(0, 0, 0, .075);


        p { max-width: 290px; }

        form {
            position: relative;
            transition: all .2s ease-in-out;
            
            input {
                width: 100%;
                padding: .7rem 1rem;
                height: auto !important;
                background: #e9ecef;
                border-radius: 8px;
                border: none !important;
                outline: none !important;
                font-size: 0.9rem;
                color: #495057;
                margin-bottom: .5rem;
                transition: all .2s ease-in-out;
            }

            .error-help-block {
                color: $danger;
                transition: all .2s ease-in-out;
                position: relative; top: -10px;
                font-size: .8rem !important;
            }
        }
    }
}