body.solliciteer {
    form.apply_form {
        input[type=file] {
            border: 2px solid $neon;
            cursor: pointer;
            padding: 6px 11px !important;
            display: flex; justify-content: center; align-items: center; gap: 10px;
            &.no-file {
                background: transparent !important;
                color: transparent;
                &::-webkit-file-upload-button, &::file-selector-button {
                    visibility: hidden;
                    display: none !important;
                }
                ~ .button__content {
                    display: flex; justify-content: center; align-items: center;
                    gap: 10px;
                    margin-top: -40px; padding: 0;
                    color: $neon;
                    position: relative;
                    z-index: 0; pointer-events: none;
                    p {
                        font-weight: 600;
                    }
                }
            }
            &:not(.no-file) {
                ~ .button__content {
                    opacity: 0; height: 0; pointer-events: none;
                }
            }

            ~ .error-help-block {
                position: absolute;
                bottom: 0; left: 0;
            }
            &:has(~ .error-help-block) ~.button__content {
                padding-bottom: 28px !important;
            }
        }
        .error-help-block {
            color: $danger;
        }
    }
    .select2 {
        order: -1;
        margin-bottom: 5px;
        .select2-selection {
            height: auto !important;
            padding: 5px 24px;
            border: 1px solid #d8d8d8;
            border-radius: 5px;
        }
        .select2-selection__arrow {
            top: 6px !important;
            right: 12px !important;
        }
    }

    .select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
        background-color: $neon;
        color: white;
    }
}

.__grid-images-floating {
    display: grid; height: 100vh;
    position: fixed;
    z-index: 20;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);

    img {
        width: 100%; height: 100%; object-fit: cover;
    }
}