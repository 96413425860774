body.advice_meeting {
    .company_images--content {
        .--grid {
            display: grid; grid-template-columns: repeat(4, 1fr);
            gap: .5rem;
            @media (max-width: $breakpoint-md) {
                grid-template-columns: repeat(3, 1fr);
            }
            @media (max-width: $breakpoint-sm) {
                grid-template-columns: repeat(2, 1fr);
            }
            .-img-holder {
                width: 100%;
                display: flex; align-items: center; justify-content: center;
                img {
                    object-fit: scale-down;
                    width: 100%; max-height: 100%;
                    @media (min-width: $breakpoint-md) {
                        filter: grayscale(1);
                    }
                    transition: all .2s ease-in-out;
                    &:hover {
                        filter: grayscale(0);
                    }
                }
            }
        }
    }
    section.subhero {
        padding-top: 200px !important;

        @media (max-width: $breakpoint-lg) {
            padding-top: 5rem !important;
        }

        .left-side {
            position: relative;
            img {
                border-radius: 5px;
                width: 100%; aspect-ratio: 1;
                position: relative;
                left: 0; top: 0; transform: translate(-50%, -60%) scale(1.375);
            }
            .cta {
                position: absolute; right: 30px; top: 0;
                background: $neon; color: #fff;
                box-shadow: 1rem 0 0 rgba(0,0,0,.085);
                width: 250px; aspect-ratio: 1;
                border-radius: 5px; padding: 20px;
                text-align: center;
                display: flex; justify-content: center; align-items: center;
                h3 {
                    font-size: 1.65rem;
                    strong { font-size: 3.15rem; }
                }
            }
        }
    }

    section.growth-slider {
        padding: 100px 0 200px !important;
        @media (max-width: $breakpoint-lg) {
            padding: 6rem 0 !important;
        }
        background: #000 !important;
        margin: 0 !important;

        h2 {
            color: #fff !important;
        }
    }

    section.plan_advice_meeting {
        .__content .text--content {
            a {
                text-decoration: none !important;
                color: $neon;
                overflow: hidden;
                display: inline-block;
                padding-bottom: 2px;
                position: relative; top: 12px;

                &::before {
                    content: '';
                    position: absolute;
                    width: 100%; height: 1px;
                    background: darken($neon, 15%);
                    bottom: 0;
                    left: 0;
                    transition: all .3s ease-in-out;
                    transform: translateX(-100%);
                }
                &:hover {
                    &::before {
                        transform: translateX(0);
                    }
                }
            }
        }
    }
}


    body:is(.advice_meeting, .solliciteer, .marketing-scan) {
        .error-help-block {
            color: $danger !important;
            font-size: .8rem !important;
        }
    }
