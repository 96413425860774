.__video-container {
    iframe { z-index: 2; }

    .loader {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #cfcfcf;
        box-shadow: 32px 0 #cfcfcf, -32px 0 #cfcfcf;
        position: absolute;
        left: 50%; top: 50%;
        transform: translate(-50%, -50%);
        animation: flash 0.5s ease-out infinite alternate;
    }

    @keyframes flash {
        0% {
            background-color: #a8a8a8;
            box-shadow: 32px 0 #a8a8a8, -32px 0 #cfcfcf;
        }
        50% {
            background-color: #cfcfcf;
            box-shadow: 32px 0 #a8a8a8, -32px 0 #a8a8a8;
        }
        100% {
            background-color: #a8a8a8;
            box-shadow: 32px 0 #cfcfcf, -32px 0 #a8a8a8;
        }
    }
}