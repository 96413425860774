section.contact-block {
    p {
        font-size: 1rem;
        color: #484848;
        margin-bottom: 0 !important;
    }
    a {
        &:hover {
            color: $neon !important;
        }
    }
    .__flex-w-divider {
        position: relative;
        &::before {
            width: 1px; height: 90px; background: $gray-300;
            content: '';
            position: absolute; top: 0; left: 50%;
            transform: translateX(-50%);
        }
    }
    .form-control {
        height: auto !important;
        padding: .9rem 1.25rem;
        background: $gray-200; border-radius: 8px;
        border: none !important; outline: none !important;
        font-size: 1rem; color: $gray-700;
        transition: all .2s ease-in-out;
    }

    .error-help-block {
        color: $danger; font-size: .8rem !important;
        transition: all .2s ease-in-out;
    }
}