@import 'variables';


//|------------------------------------------------------------------|//
//| DEPENDENCIES
@import '~bootstrap/scss/bootstrap';
@import '~owl.carousel';
@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';
@import '~@fortawesome/fontawesome-pro';
@import '~aos/dist/aos.css';


//|------------------------------------------------------------------|//
//| STANDARD CSS (template)
@import 'static/plain';


//|------------------------------------------------------------------|//
//| GLOBAL
@import 'typography';
@import 'global';
@import 'footer';
@import 'buttons';
@import 'header';
@import 'helper';
@import '_error';
@import 'animations';


//|------------------------------------------------------------------|//
//| COMPONENTS

//|A-B-C
@import 'components/about-page-block';
@import 'components/case-panel';
@import 'components/contact';
@import 'components/cta-w-video';

//|D-E-F
@import 'components/form-parent';
@import 'components/faq';

//|G-H-I
@import 'components/growth-slider';

//|J-K-L
@import 'components/lets-work-together';

//|M-N-O

//|P-Q-R
@import 'components/plain-section';

//|S-T-U
@import 'components/services';
@import 'components/subhero';
@import 'components/text-image';

//|V-W-X
@import 'components/video-loader';
@import 'components/whatsapp';

//|Y-Z


//|------------------------------------------------------------------|//
//| PAGE-WISE
@import 'pages/homepage';
@import 'pages/text';
@import 'pages/services';
@import 'pages/marketing-scan';
@import 'pages/solliciteer';
@import 'pages/plan-adviesgesprek';
@import 'pages/case-detail';