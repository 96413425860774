body.case-detail {

    section.subhero {
        padding: 250px 0 100px;
        @media (max-width: $breakpoint-lg) {
            padding-bottom: 0 !important;
        }
        position: relative !important;
        overflow: hidden;
        background-size: cover;
        background-position: center;

        &::before {
            width: 100%;
            height: 100%;
            content: '';
            position: absolute;
            z-index: 1;
            right: 0;
            bottom: 0;
            left: 0;
            top: 0;
            background: rgba(0, 0, 0, .45);
        }
        .background-video {
            position: absolute;
            left: 0; top: 50%; right: 0;
            width: 100%;
            height: 100vh; /* Full viewport height */
            overflow: hidden;
            transform: translateY(-50%);
        }

        #__bg-video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover; /* Ensures the video covers the area */
        }

        > .container > .row {
            position: relative;
            z-index: 1;
        }
        .container {
            position: relative;
            z-index: 3;
        }

        .fact {
            @media (max-width: $breakpoint-lg) {
                padding: 20px 1rem;
            }

            p {
                color: $gray-200 !important;
                font-size: .95rem !important;
            }
        }
    }

    section.content-block {
        position: relative;
        .__beam {
            position: absolute;
            left: 0; top: 50%;
            transform: translateY(-50%);
            width: 100%; height: 300px;
            background: $neon;
            z-index: 0;
            @media (max-width: $breakpoint-lg) {
                height: 100%;
            }
        }

        &:has(.__beam) {
            h1,h2,h3,h4,h5,h6 {
                margin: 0 !important;
                color: #fff !important;
            }

            .btn {
                @media (min-width: calc($breakpoint-lg + 1px)) {
                    position: absolute;
                    left: .75rem; top: 50%;
                    transform: translateY(150px - 27.3px );
                    font-weight: 800;
                }
                @media (max-width: $breakpoint-lg) {
                    margin-top: 1.5rem;
                    margin-bottom: -4rem;
                }
            }

            @media (max-width: $breakpoint-lg) {
                .col-lg-6 {
                    display: none !important;
                }

                margin-bottom: 2.25rem;
            }
        }
        [class*="col-"] {
            position: relative; z-index: 1;
        }
    }


    section.content-block:has(.__beam) {
        @media (max-width: $breakpoint-lg) {
            margin-bottom: 7.5rem !important;
        }
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}