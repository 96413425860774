section.plain-section {
    background: #000;
    background-image: url('/img/subhero.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 140px;
    color: #fff;
}

section.plain-section--content {
    h2 {
        line-height: 1.8;
        font-size: 1.2rem;
        margin-top: 2rem;
        margin-bottom: 0;
    }
    p {
        line-height: 1.8;
        font-size: 1rem;
        font-weight: 300 !important;
        margin-bottom: 1.2rem;
        color: #484848 !important;
        strong {
            font-weight: 600 !important;
            font-size: 1rem;
        }
    }
    ul {
        padding-left: 1rem;
    }
    li {
        line-height: 1.8 !important;
        font-size: 1rem !important;
        font-weight: 300 !important;
        color: #484848 !important;
    }
    a:not(.btn) {
        text-decoration: none;
        color: $neon;
        &:hover {
            text-decoration: underline;
        }
    }
    .primary:hover {
        text-decoration: none !important;
    }
    table {
        margin-bottom: 2.5rem;
        color: #484848 !important;
    }
}