@each $name, $size in $grid-breakpoints {
    @media (max-width: #{$size}) {
        .rm-#{$name} { display: none !important; }
        .overflow-#{$name}-hidden { overflow: hidden; }
        .aspect-16-9-#{$name} { aspect-ratio: 16/9; }
    }

    @media (min-width: calc(#{$size} + 1px)) {
        .mt--1-#{$name} { margin-top: -1rem !important; }
        .mt--2-#{$name} { margin-top: -2rem !important; }
        .mt--3-#{$name} { margin-top: -3rem !important; }
        .mt--4-#{$name} { margin-top: -4rem !important; }
        .mt--5-#{$name} { margin-top: -5rem !important; }
        .mt--6-#{$name} { margin-top: -6rem !important; }
        .mt--7-#{$name} { margin-top: -7rem !important; }
        .mt--8-#{$name} { margin-top: -8rem !important; }
        .mt--9-#{$name} { margin-top: -9rem !important; }
        .mt--10-#{$name} { margin-top: -10rem !important; }
    }
}

.ob-fit-cover {
    object-fit: cover;
}

.bg-lighter                 { background: #f2f2f2; }

.transition                 { transition: all .3s ease-in-out; }

.hover-primary              { &:hover { color: $primary !important; } }
.hover-pink                 { &:hover { color: $pink-color !important; } }

.fw-800                     { font-weight: 800 !important; }