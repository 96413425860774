.services__parent:last-of-type {
    padding: 75px 0 15px;

    .mt-less-135 {
        margin: 0 !important;
    }
}

div.services__parent {
    @media (max-width: $breakpoint-sm) {
        .__case {
            margin: 0 !important;
            padding: 4rem 0;
            border-bottom: 1px solid $gray-300;

            img, br { display: none !important; }
            h3 { margin-top: 0; }
        }

        .row {
            & > :nth-child(6) .__case {
                border-color: transparent;
            }
        }
    }
}

@media (max-width: $breakpoint-sm) {
    .services__parent:first-of-type {
        .__case-0 {
            padding-top: 1rem !important;
        }

        .__case-5 {
            border-bottom: 1px solid $gray-300 !important;
        }
    }
    .services__parent:last-of-type {
        padding-bottom: 0 !important;
    }

    .cases-small__parent {
        padding-top: 0 !important;
    }
}


.__readmore-btn {
    display: inline-block !important;
    color: $gray-600;
    display: inline-block;
    font-size: 1rem;
    padding: 8px 1rem;
    transition: all .2s ease-in-out;
    @media (max-width: $breakpoint-sm) {
        font-size: .8rem;
        padding: 3px 5px;
    }
    border-radius: 3px;
    border: 1px solid $neon;

    i {
        color: $neon;
        margin-left: .35rem;
        transition: all .2s ease-in-out;
    }

    &:hover {
        i {
            margin-left: 1rem;
        }
    }
}