section.growth-slider, section.success-block {
    .slider {
        .slick-list {
            overflow: visible !important;

            .slick-slide {
                margin: 0 1rem 0 0;
            }
        }
        .slick-arrow {
            top: initial !important; left: initial !important;
            bottom: -70px; right: 0 !important;
            color: $neon !important; font-size: 1.25rem !important;
            width: 35px; height: 35px; display: flex; align-items: center; justify-content: center;
            background: #f2f2f2;
            cursor: pointer; transition: all .2s ease-in-out;

            &.slick-prev { margin-right: 50px; }
            &::before { content: none !important; }
            &:hover {
                background: $neon !important ;
                color: #fff !important;
            }
        }

        .slick-dots {
            text-align: center !important;
            margin: auto;
            position: relative;
            width: 90%; height: 8px;
            display: grid; gap: 8px;
            list-style: none !important;
            @media (max-width: $breakpoint-sm) {
                margin: 0 !important;
                gap: 4px !important;
                width: 100% !important; max-width: calc(100vw - 1rem) !important;
            }
            li {
                padding: 0; margin: 0;
                height: 8px; width: 100%;
                transition: all .2s;
                background: #d3d3d33b;

                button::before {
                    display: none; content: none;
                }
                &.slick-active,&:hover {
                    background: $neon;
                }
            }
        }
        .item {
            background: #f2f2f2;
            aspect-ratio: 1/1;
            padding: 30px;
            overflow: hidden; position: relative;
            text-decoration: none !important;
            transition: all .2s ease-in-out;
            &:hover { .__background { transform: scale(1.075); } }
            &::before {
                width: 100%; height: 100%;
                position: absolute; left: 0; top: 0;
                z-index: 1; content: '';
                background: rgba(0,0,0,.5);
            }
            &.services::before {
                background: linear-gradient(0deg, rgba(0,0,0,.5) 0% 40%, rgba(0,0,0,0) 60% 100%) !important;
            }
            .__background {
                transition: all .2s ease-in-out;
                position: absolute;
                left: 0; top: 0;
                height: 100%; width: 100%; object-fit: cover;
                z-index: 0;
            }
            .content,.visual { --fa-font-light: relative; z-index: 2; }
            .visual {
                img {
                    height: 100px;
                }
            }
            .content {
                * { color: #fff;  text-decoration: none !important;}
                @media (min-width: $breakpoint-lg) {
                    max-width: 85%;
                }
                h2 {
                    font-size: 1.85rem !important;
                    line-height: 1.3 !important;
                    text-decoration: none !important;
                    transition: all .2s ease-in-out;
                }
                p {
                    font-size: 1.175rem;
                    margin: 0 !important;
                    line-height: 1.15;
                }
            }
        }
    }
}