// -#-#-#-#-#- STANDARD VARIABLES -#-#-#-#-#- //
// -- BREAKPOINTS -- //
$breakpoint-sm:   576px;
$breakpoint-md:   767px;
$breakpoint-lg:   991px;
$breakpoint-xl:   1200px;
$breakpoint-xxl:  1400px;
$spacer: 1.5rem;
$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: $spacer * 5,
        7: $spacer * 10,
        n1: -$spacer * .25,
        n2: -$spacer * .5,
        n3: -$spacer,
        n4: -$spacer * 1.5,
        n5: -$spacer * 3
);

$text-sizing: (
        h1  3.5rem      1.3     300,
        h2  3rem        1.3     300,
        h3  2.25rem     1.3     300,
        h4  2rem        1.3     300,
        h5  1.75rem     1.3     300,
        h6  1.5rem      1.3     300,
);

//--- CUSTOM VARIABLES FOR WEBSITE ---//
$white-color: #ffffff;
$black-color: #000000;
$green-color: #13f40c;
$green-color2: #6ded69;
$green-color3: #12ba0d;
$yellow-color: #ffec00;
$yellow-color2: #EFE56B;
$yellow-color3: #ddcc03;
$pink-color: #ff008b;
$pink-color2: #f861b4;
$pink-color3: #c10069;
$blue-color: #0568fe;
$blue-color2: #00ede8;
$blue-color3: #3e8bff;
$blue-color4: #004aba;
$orange-color: #e83323;
$lightorange-color: #ff691c;
$lightorange-color2: #f78f5a;
$lightorange-color3: #d55513;
$red-color: #ff0000;
$purple-color: #9f06fe;
$purple-color2: #b544fc;
$purple-color3: #7500be;
$neon: #5DDFA7;

$gray-color: rgba(0, 0, 0, 0.05);
$gray-color2: #bebebe;
$gray-color3: #f8f8f8;
$gray-color4: #d8d8d8;
$gray-color5: #7f7f7f;

$darkgray-color: #5d5d5d;