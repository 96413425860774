/* mixin */
/* color */
/* base static */

img {
    max-width: 100%;
}

.container {
    max-width: 1030px;
}
.container.container-w1120 {
    max-width: 1120px;
}

.row-less-26 {
    margin-left: -26px;
    margin-right: -26px;
}
@media (max-width: 991.5px) {
    .row-less-26 {
        margin-left: -8px;
        margin-right: -8px;
    }
}
.row-less-26 > * {
    padding-left: 26px;
    padding-right: 26px;
}
@media (max-width: 991.5px) {
    .row-less-26 > * {
        padding-left: 8px;
        padding-right: 8px;
    }
}

/* header static */
.header-wrap {
    padding: 80px 0px 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    transition: all 0.3s ease;
}

@media (max-width: 1023px) {
    .header-wrap .m-toggle {
        display: inline-block;
    }
}
.header-wrap .logo {
    width: auto;
}
.header-wrap.sticky {
    position: fixed;
    padding-top: 20px;
    background: rgba(0, 0, 0, 0.9);
}

@media (max-width: 1023px) {
    nav.main-nav {
        display: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        position: absolute;
        background: #f2f2f2;
        padding: 150px 80px 80px;
        overflow-x: auto;
    }
}
@media (max-width: 1023px) {
    nav.main-nav {
        padding: 150px 35px 35px;
    }
}
nav.main-nav .close-btn {
    display: none;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background: url(/images/close-icon.svg) no-repeat center;
    top: 35px;
    position: absolute;
}
@media (max-width: 1023px) {
    nav.main-nav .close-btn {
        display: block;
    }
}
nav.main-nav > ul {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none;
}
@media (max-width: 1023px) {
    nav.main-nav > ul {
        display: block;
    }
}
nav.main-nav > ul > li {
    position: relative;
    margin-right: 35px;
}
@media (max-width: 1023px) {
    nav.main-nav > ul > li {
        border-bottom: solid 1px #c2c2c2;
        margin-right: 0;
    }
}
nav.main-nav > ul > li > a {
    display: block;
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    line-height: 18px;
    text-decoration: none;
}
nav.main-nav > ul > li > a.active {
    color: #ff008b;
}
@media (max-width: 1023px) {
    nav.main-nav > ul > li > a {
        color: #1a1a1a;
        font-size: 28px;
        font-weight: 700;
        line-height: 28px;
        padding: 18px 0px;
        position: relative;
    }
}
nav.main-nav > ul > li i {
    display: inline-block;
    position: absolute;
    top: -2px;
    right: -16px;
    width: 13px;
    height: 13px;
    font-size: 8px;
    font-weight: 700;
    text-align: center;
    line-height: 13px;
    font-style: normal;
    border-radius: 13px;
    color: #ffffff;
    background: #ff008b !important;
}
@media (max-width: 1023px) {
    nav.main-nav > ul > li i {
        display: none;
    }
}
nav.main-nav > ul > li span {
    display: none;
    position: absolute;
    top: -2px;
    right: -24px;
    width: 65px;
    height: 65px;
    z-index: 2;
    background: url(/images/arrow-right.svg) no-repeat center;
}
@media (max-width: 1023px) {
    nav.main-nav > ul > li span {
        display: block;
    }
    nav.main-nav > ul > li span.active {
        transform: rotate(90deg);
    }
}
nav.main-nav > ul > li > ul {
    position: absolute;
    top: 100%;
    left: 0px;
    margin: 0px;
    list-style: none;
    width: max-content;
    background: #ffffff;
    padding: 10px 15px;
    display: none;
}
@media (min-width: 1024px) {
    nav.main-nav > ul > li > ul {
        display: none !important;
    }
}
@media (max-width: 1023px) {
    nav.main-nav > ul > li > ul {
        position: relative;
        width: 100%;
        background: transparent;
        padding-top: 0px;
    }
}
nav.main-nav > ul > li > ul > li a {
    display: block;
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-decoration: none;
    padding: 6px 5px 4px;
}
nav.main-nav > ul > li > ul > li a:hover {
    background: #d8d8d8;
}
@media (max-width: 1023px) {
    nav.main-nav > ul > li > ul > li a:hover {
        background: transparent;
    }
}
nav.main-nav > ul > li:hover ul {
    display: block;
}
@media (max-width: 1023px) {
    nav.main-nav > ul > li:hover ul {
        display: none;
    }
}
@media (max-width: 1023px) {
    nav.main-nav > ul > li.submenu:after {
        position: absolute;
        top: -2px;
        right: -24px;
        width: 65px;
        height: 65px;
        content: "";
        z-index: 2;
        background: url(/images/arrow-right.svg) no-repeat center;
    }
}
@media (max-width: 1023px) {
    nav.main-nav > ul > li.submenu.active:after {
        transform: rotate(90deg);
    }
}

/* footer static */
.footer-top-block {
    position: relative;
    padding: 62px 0;
}
@media (max-width: 991.5px) {
    .footer-top-block {
        padding-bottom: 0px;
    }
}
.footer-top-block .container {
    position: relative;
    z-index: 2;
}
.footer-top-block .footer-logo {
    max-width: 240px;
    display: inline-block;
    margin-bottom: 20px;
}
.footer-top-block p {
    font-size: 16px;
    line-height: 18px;
    color: #ffffff;
    margin-bottom: 27px;
}
@media (max-width: 799px) {
    .footer-top-block p br {
        display: none;
    }
}
.footer-top-block .btn {
    margin-right: 22px;
}
@media (max-width: 799px) {
    .footer-top-block .btn {
        margin-right: 15px;
    }
}
.footer-top-block .social-links {
    padding-top: 26px;
}
@media (max-width: 767.5px) {
    .footer-top-block .social-links {
        padding-top: 80px;
    }
}
.footer-top-block .social-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: end;
}
@media (max-width: 767.5px) {
    .footer-top-block .social-links ul {
        justify-content: start;
    }
}
.footer-top-block .social-links ul li {
    margin-left: 20px;
}
.footer-top-block .social-links ul li:first-child {
    margin-left: 0px;
}
.footer-top-block .social-links ul li a {
    color: #ffffff;
    font-size: 32px;
}
.footer-top-block .footer-top-block-links {
    padding-top: 76px;
}
.footer-top-block .footer-top-block-links strong {
    color: #ffffff;
    line-height: 16px;
    font-size: 16px;
    font-weight: 700;
}
.footer-top-block .footer-top-block-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
@media (max-width: 991.5px) {
    .footer-top-block .footer-top-block-links ul {
        margin-bottom: 80px;
    }
}
.footer-top-block .footer-top-block-links ul li a {
    display: inline-block;
    color: #f2f2f2;
    font-size: 16px;
    text-decoration: none;
}
@media (max-width: 539px) {
    .footer-top-block .footer-top-block-links ul li a {
        font-size: 11px;
        line-height: 18px;
    }
}

.footer-top-middle {
    padding: 36px 0px;
    background: #000000;
}
.footer-top-middle .column-left {
    width: 48%;
}
@media (max-width: 767.5px) {
    .footer-top-middle .column-left {
        width: 100%;
    }
}
.footer-top-middle .column-left p {
    color: #ffffff;
    margin-bottom: 10px;
}
.footer-top-middle .column-left h3 {
    font-size: 32px;
    color: #ffffff;
    margin-bottom: -5px;
}
@media (max-width: 767.5px) {
    .footer-top-middle .column-left h3 {
        font-size: 28px;
        margin-bottom: 42px;
    }
}
.footer-top-middle .column-right {
    width: 52%;
}
@media (max-width: 767.5px) {
    .footer-top-middle .column-right {
        width: 100%;
    }
}

.footer-top-bottom {
    padding: 34px 0px;
}
.footer-top-bottom .column-left, .footer-top-bottom .column-right {
    width: auto;
}
.footer-top-bottom .copyright-text {
    color: #5d5d5d;
    font-size: .9rem;
    font-weight: 300;
}
@media (max-width: 539px) {
    .footer-top-bottom .copyright-text {
        font-size: .8rem;
    }
}
.footer-top-bottom ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: end;
}
.footer-top-bottom ul li {
    margin-left: 18px;
}
@media (max-width: 539px) {
    .footer-top-bottom ul li {
        margin-left: 15px;
    }
    .footer-top-bottom ul li:first-child {
        margin-left: 0px;
    }
}
.footer-top-bottom ul li a {
    color: #5d5d5d;
    font-size: .9rem;
    font-weight: 300;
    text-decoration: none;
    display: block;
    line-height: 24px;
    &:hover {
        color: $neon;
    }
    &.active {
        color: $neon;
    }
}
@media (max-width: 539px) {
    .footer-top-bottom ul li a {
        font-size: .8rem;
    }
}

///////////////////////////////////////////////////////////////////////////

/* banner static */
.banner-wrap {
    padding-top: 280px;
    padding-bottom: 100px;
    position: relative;
}
@media (max-width: 500px) {
    .banner-wrap {
        padding-top: 180px;
    }
}
.banner-wrap .banner-overlay {
    position: relative;
    z-index: 2;
}
.banner-wrap h1 {
    color: #ffffff;
    margin-bottom: 42px;
}
.banner-wrap.home h1 {
    margin-bottom: 100px !important;
}
.banner-wrap p {
    color: #ffffff;
    font-size: 24px;
    font-weight: 300;
    font-style: normal;
    margin-bottom: 42px;
}
@media (max-width: 991.5px) {
    .banner-wrap p {
        font-size: 22px;
    }
}
@media (max-width: 639px) {
    .banner-wrap p {
        font-size: 20px;
    }
}
.banner-wrap .btn {
    margin-right: 24px;
}
@media (max-width: 639px) {
    .banner-wrap .btn {
        margin-right: 15px;
    }
}

.banner-wrap.banner-wrap-subpage {
    padding-bottom: 80px;
}
.banner-wrap.banner-wrap-subpage.pb-190 {
    padding-bottom: 190px;
}
.banner-wrap.banner-wrap-subpage.pb-280 {
    padding-bottom: 280px;
}
@media (max-width: 991.5px) {
    .banner-wrap.banner-wrap-subpage.pb-280 {
        padding-bottom: 20%;
    }
}
@media (max-width: 767.5px) {
    .banner-wrap.banner-wrap-subpage.pb-280 {
        padding-bottom: 15%;
    }
}
.banner-wrap.banner-wrap-subpage.mh-800 {
    min-height: 800px;
}
.banner-wrap.banner-wrap-subpage.mh-942 {
    min-height: 942px;
}
.banner-wrap.banner-wrap-subpage .yellow-bg.mt-65 {
    margin-top: 65px;
}
.banner-wrap.banner-wrap-subpage .growth-panel {
    padding: 48px 0px 38px;
}
.banner-wrap.banner-wrap-subpage .growth-panel p {
    font-size: 18px;
    font-weight: 300;
    color: #000000;
    line-height: 24px;
    margin-bottom: 0px;
}

.form-control {
    padding: 5px 24px;
    height: 43px;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 400;
    border: 1px solid #d8d8d8;
    background: #ffffff;
}
@media (max-width: 767.5px) {
    .form-control {
        font-size: 15px;
    }
}
.form-control.error-form-control {
    font-size: 14px;
    font-weight: 300;
    border: 2px solid #e83323 !important;
    color: #ff0000;
}
.form-control.error-form-control::placeholder {
    color: #ff0000;
}
.form-control::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: #bebebe;
}

textarea.form-control {
    height: 145px;
    resize: none;
    padding: 10px 24px;
}

.form-control-file {
    position: relative;
    text-align: center;
    color: #0568fe;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    height: 44px;
    border-radius: 22px;
    border: 2px solid #0568fe;
    overflow: hidden;
}
@media (max-width: 767.5px) {
    .form-control-file {
        font-size: 14px;
    }
}
.form-control-file i.fa-cloud-arrow-up {
    font-size: 22px;
    margin-left: 8px;
    vertical-align: middle;
}
@media (max-width: 767.5px) {
    .form-control-file i.fa-cloud-arrow-up {
        font-size: 18px;
    }
}
.form-control-file input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
}
.ui-widget.ui-widget-content {
    border: 0px;
    padding: 0px;
    width: 100%;
}

.ui-datepicker .ui-datepicker-header {
    border-radius: 24px;
    background: #0568fe;
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
}

.ui-datepicker table {
    position: relative;
    margin-top: 16px;
    color: #000000;
    font-size: 14px;
    font-weight: 700;
}
.ui-datepicker table:after {
    content: "";
    border: 1px solid #d8d8d8;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 22px;
}

.ui-datepicker th {
    color: #7f7f7f;
    font-size: 14px;
    font-weight: 700;
}

.ui-datepicker th,
.ui-datepicker td {
    height: 44px;
}

.ui-datepicker table thead,
.ui-datepicker table tbody {
    position: relative;
    z-index: 3;
}

.ui-datepicker td span,
.ui-datepicker td a {
    text-align: center;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
    border: 0px;
    background: transparent;
    color: #000000;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active, .ui-button:active,
.ui-button.ui-state-active:hover {
    position: relative;
    color: #ffffff;
}
.ui-state-active:after,
.ui-widget-content .ui-state-active:after,
.ui-widget-header .ui-state-active:after,
a.ui-button:active:after, .ui-button:active:after,
.ui-button.ui-state-active:hover:after {
    content: "";
    top: 50%;
    left: 50%;
    width: 26px;
    height: 26px;
    background-color: #0568fe;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: -1;
    border-radius: 100%;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-button:hover,
.ui-button:focus {
    border: 0px;
    background: transparent;
}

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
    opacity: 0;
}

.ui-datepicker-next, .ui-datepicker-prev {
    cursor: pointer;
}

.ui-datepicker .ui-datepicker-prev {
    background: url(/images/Polygon_1.svg) no-repeat center;
}

.ui-datepicker .ui-datepicker-next {
    background: url(/images/Polygon_2.svg) no-repeat center;
}

.about-banner-slider {
    position: relative;
}
.about-banner-slider .slick-list {
    overflow: visible !important;
    margin-left: -21px;
    margin-right: -21px;
}
@media (max-width: 767.5px) {
    .about-banner-slider .slick-list {
        margin-left: -8px;
        margin-right: -8px;
    }
}
.about-banner-slider .slick-list .slick-slide {
    padding-left: 21px;
    padding-right: 21px;
}
@media (max-width: 767.5px) {
    .about-banner-slider .slick-list .slick-slide {
        padding-left: 8px;
        padding-right: 8px;
    }
}
.about-banner-slider .slick-arrow {
    position: absolute;
    margin-top: 18px;
    top: 100%;
    width: 28px;
    height: 28px;
    border: 0px;
    font-size: 0px;
    cursor: pointer;
}
.about-banner-slider .slick-arrow.slick-prev {
    left: 0;
    background: url(/images/iconmonstr-arrow-left-thin-white.svg) no-repeat center;
}
.about-banner-slider .slick-arrow.slick-next {
    right: 0;
    background: url(/images/iconmonstr-arrow-right-thin-white.svg) no-repeat center;
}

.onlyslider-block {
    padding-top: 10px;
    padding-bottom: 115px;
}
.onlyslider-block.pt-110 {
    padding-top: 110px;
}
.onlyslider-block.pb-70 {
    padding-bottom: 70px;
}
.onlyslider-block h2 {
    color: #ff008b;
    margin-bottom: 0px;
}



.arrow-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.arrow-list ul li {
    position: relative;
    color: #000000;
    font-size: 24px;
    font-weight: 700;
    line-height: 26px;
    padding-left: 50px;
    margin-bottom: 26px;
}
.arrow-list ul li a {
    color: #000000;
    text-decoration: none;
}
@media (max-width: 991.5px) {
    .arrow-list ul li {
        font-size: 22px;
    }
}
@media (max-width: 767.5px) {
    .arrow-list ul li {
        font-size: 20px;
    }
}
.arrow-list ul li:last-child {
    margin-bottom: 0px;
}
.arrow-list ul li:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 26px;
    content: "";
    background: url(/images/iconmonstr-arrow-right-thin.svg) no-repeat center;
}

.check-list.check-list-sm ul li {
    font-size: 20px;
    font-weight: 300;
    line-height: 36px;
}
@media (max-width: 991.5px) {
    .check-list.check-list-sm ul li {
        font-size: 18px;
        line-height: 34px;
    }
}
@media (max-width: 767.5px) {
    .check-list.check-list-sm ul li {
        font-size: 16px;
        line-height: 30px;
    }
}
.check-list.check-list-sm ul li:after {
    top: 8px;
}

.squareblue-check-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.squareblue-check-list ul li {
    position: relative;
    color: #000000;
    font-size: 24px;
    font-weight: 400;
    line-height: 42px;
    padding-left: 50px;
    margin-bottom: 7px;
}
@media (max-width: 991.5px) {
    .squareblue-check-list ul li {
        font-size: 22px;
        line-height: 38px;
    }
}
@media (max-width: 767.5px) {
    .squareblue-check-list ul li {
        font-size: 20px;
        line-height: 34px;
    }
}
.squareblue-check-list ul li:after {
    position: absolute;
    width: 8px;
    height: 8px;
    top: 17px;
    left: 0;
    content: "";
    background: $neon;
}
.squareblue-check-list.squareblue-check-list-sm ul li {
    font-size: 20px;
    font-weight: 300;
    line-height: 36px;
}
@media (max-width: 991.5px) {
    .squareblue-check-list.squareblue-check-list-sm ul li {
        font-size: 18px;
        line-height: 34px;
    }
}
@media (max-width: 767.5px) {
    .squareblue-check-list.squareblue-check-list-sm ul li {
        font-size: 16px;
        line-height: 30px;
    }
}
.squareblue-check-list.squareblue-check-list-sm ul li:after {
    top: 14px;
}

.squareblue2-check-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.squareblue2-check-list ul li {
    position: relative;
    color: #000000;
    font-size: 24px;
    font-weight: 400;
    line-height: 42px;
    padding-left: 50px;
    margin-bottom: 7px;
}
@media (max-width: 991.5px) {
    .squareblue2-check-list ul li {
        font-size: 22px;
        line-height: 38px;
    }
}
@media (max-width: 767.5px) {
    .squareblue2-check-list ul li {
        font-size: 20px;
        line-height: 34px;
    }
}
.squareblue2-check-list ul li:after {
    position: absolute;
    width: 8px;
    height: 8px;
    top: 17px;
    left: 0;
    content: "";
    background: #00ede8;
}
.squareblue2-check-list.squareblue2-check-list-sm ul li {
    font-size: 20px;
    font-weight: 300;
    line-height: 36px;
}
@media (max-width: 991.5px) {
    .squareblue2-check-list.squareblue2-check-list-sm ul li {
        font-size: 18px;
        line-height: 34px;
    }
}
@media (max-width: 767.5px) {
    .squareblue2-check-list.squareblue2-check-list-sm ul li {
        font-size: 16px;
        line-height: 30px;
    }
}
.squareblue2-check-list.squareblue2-check-list-sm ul li:after {
    top: 14px;
}

.squarepink-check-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.squarepink-check-list ul li {
    position: relative;
    color: #000000;
    font-size: 24px;
    font-weight: 400;
    line-height: 42px;
    padding-left: 50px;
    margin-bottom: 7px;
}
@media (max-width: 991.5px) {
    .squarepink-check-list ul li {
        font-size: 22px;
        line-height: 38px;
    }
}
@media (max-width: 767.5px) {
    .squarepink-check-list ul li {
        font-size: 20px;
        line-height: 34px;
    }
}
.squarepink-check-list ul li:after {
    position: absolute;
    width: 8px;
    height: 8px;
    top: 17px;
    left: 0;
    content: "";
    background: #ff008b;
}
.squarepink-check-list.squarepink-check-list-sm ul li {
    font-size: 20px;
    font-weight: 300;
    line-height: 36px;
}
@media (max-width: 991.5px) {
    .squarepink-check-list.squarepink-check-list-sm ul li {
        font-size: 18px;
        line-height: 34px;
    }
}
@media (max-width: 767.5px) {
    .squarepink-check-list.squarepink-check-list-sm ul li {
        font-size: 16px;
        line-height: 30px;
    }
}
.squarepink-check-list.squarepink-check-list-sm ul li:after {
    top: 14px;
}

.about-block {
    padding-bottom: 72px;
}
.about-block figure {
    position: relative;
    margin-top: -50px;
    margin-bottom: 114px;
    z-index: 9;
}
@media (max-width: 1024px) {
    .about-block figure {
        display: none;
    }
}
.about-block figure img {
    max-width: 100%;
}
.about-block p {
    line-height: 36px;
    margin-bottom: 35px;
}


.about-page-block {
    padding-top: 118px;
    padding-bottom: 72px;
}
@media (min-width: calc($breakpoint-md + 1px)) {
    body:has(.__video) .about-page-block {
        margin-top: 200px;
    }
}


.growth-block {
    padding: 48px 0px 40px;
}
.growth-block .growth-panel {
    position: relative;
}
.growth-block .growth-panel.growth-panel-border:after {
    position: absolute;
    top: 0;
    right: -12px;
    width: 2px;
    height: 100%;
    content: "";
    background: #fff;
}

.growth-panel .growth-panel-percentage {
    color: #000000;
    font-size: 54px;
    font-weight: 700;
    line-height: 54px;
    margin-bottom: 8px;
}
@media (max-width: 991.5px) {
    .growth-panel .growth-panel-percentage {
        font-size: 48px;
        line-height: 50px;
    }
}
.growth-panel .growth-panel-percentage i {
    display: inline-block;
    margin-right: 24px;
}
.growth-panel .growth-panel-percentage i img {
    display: block;
}
.growth-panel strong {
    display: block;
    color: #000000;
    font-size: 22px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 15px;
}
.growth-panel p {
    margin-bottom: 40px;
}
.growth-panel img {
    display: inline-block;
}

.success-block {
    position: relative;
    padding-top: 305px;
}
.success-block .black-bg {
    padding-bottom: 236px;
}
.success-block h2 {
    color: #ff008b;
    margin-bottom: 0px;
    max-width: calc(100% - 50px);
}
.success-block .successStories-slider {
    margin-bottom: 118px;
}
.success-block .success-block-title {
    margin-top: -190px;
}
.success-block .success-block-title .column-top-left {
    width: calc(100% - 150px);
}
.success-block .black-bg .column-left h3 {
    color: #ffec00;
}
.success-block .black-bg .column-left p {
    color: #ffffff;
}
.success-block .black-bg .column-left .form-control {
    border: 2px solid #ffffff;
}
.success-block .black-bg .column-right h3 {
    color: #ff008b;
}
.success-block .black-bg .column-right .suggestion {
    position: relative;
}
.success-block .black-bg .column-right .suggestion h3 {
    color: #0568fe;
}
.success-block .black-bg .column-right .author-panel strong, .success-block .black-bg .column-right .author-panel span {
    color: #ffffff;
}
.success-block .yellow-bg .column-left h3 {
    color: #0568fe;
}
.success-block .yellow-bg .column-left .form-control {
    border: 2px solid #ffffff;
}
.success-block .yellow-bg .column-right h3 {
    margin-bottom: 18px;
}
.success-block .yellow-bg .column-right .author-panel {
    padding-top: 25px;
}
.success-block .yellow-bg .column-right .author-panel i {
    width: 120px;
    height: 120px;
    margin-right: 22px;
}
@media (max-width: 836px) {
    .success-block .yellow-bg .column-right .author-panel i {
        margin-bottom: 20px;
    }
}
@media (max-width: 767.5px) {
    .success-block .yellow-bg .column-right .author-panel i {
        margin-bottom: 0px;
    }
}
.success-block .column-left {
    max-width: 410px;
    width: 100%;
}
@media (max-width: 1159px) {
    .success-block .column-left {
        max-width: calc(100% - 80px);
    }
}
@media (max-width: 991.5px) {
    .success-block .column-left {
        max-width: calc(100% - 20px);
    }
}
@media (max-width: 767.5px) {
    .success-block .column-left {
        max-width: 100%;
        padding-bottom: 90px;
    }
}
.success-block .column-left h3 {
    margin-bottom: 15px;
}
@media (max-width: 991.5px) {
    .success-block .column-left h3 br {
        display: none;
    }
}
.success-block .column-left p {
    margin-bottom: 34px;
}
@media (max-width: 991.5px) {
    .success-block .column-left p {
        line-height: 30px;
    }
    .success-block .column-left p br {
        display: none;
    }
}
.success-block .column-left .btn.btn-primary {
    width: 100%;
    display: block;
}
.success-block .column-right {
    margin-left: 80px;
}
@media (max-width: 991.5px) {
    .success-block .column-right {
        margin-left: 20px;
    }
}
@media (max-width: 767.5px) {
    .success-block .column-right {
        padding-top: 90px;
        margin-left: 0px;
    }
}
.success-block .column-right p {
    line-height: 36px;
}
@media (max-width: 991.5px) {
    .success-block .column-right p {
        line-height: 30px;
    }
}
.success-block .column-right .author-panel {
    padding: 40px 0px;
    margin-bottom: 38px;
}
@media (max-width: 991.5px) {
    .success-block .column-right .author-panel {
        margin-bottom: 24px;
    }
}
@media (max-width: 767.5px) {
    .success-block .column-right .author-panel {
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
}
.success-block.insight-block {
    padding-top: 190px;
}
.success-block.insight-block .border-end-2 {
    border-right: solid 2px #ffffff;
}
@media (max-width: 767.5px) {
    .success-block.insight-block .border-end-2 {
        border-right: 0px;
        border-bottom: solid 2px #ffffff;
    }
}
.success-block.success-block-style2 {
    padding: 0px;
}
.success-block.success-block-style2 .black-bg {
    padding-top: 118px;
    padding-bottom: 210px;
}
.success-block.insight-block.insight-block-style2 {
    padding-top: 0px;
}
.success-block.insight-block.insight-block-style2 .yellow-bg {
    padding-top: 125px;
    padding-bottom: 245px;
}

.author-panel i {
    display: block;
    width: 80px;
    height: 80px;
    overflow: hidden;
    margin-right: 16px;
}
.author-panel i img {
    width: 100%;
    height: 100%;
}
.author-panel strong {
    font-size: 16px;
    font-weight: 500;
    line-height: 15px;
    display: block;
    color: #000000;
    margin-bottom: 5px;
}
.author-panel span {
    line-height: 15px;
    font-size: 13px;
    font-weight: 400;
    display: block;
    color: #000000;
}
.author-panel a {
    display: inline-block;
    color: #000000;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    text-decoration: none;
}

.successStories-panel {
    position: relative;
}
.successStories-panel figure {
    margin: 0;
}
.successStories-panel figure img {
    width: 100%;
    max-width: inherit;
    height: 480px;
    object-fit: cover;
}
.successStories-panel figcaption {
    padding: 0px 44px 35px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.75) 0%, rgba(84, 84, 84, 0) 100%);
}
@media (max-width: 539px) {
    .successStories-panel figcaption {
        padding: 0px 35px 35px;
    }
}
.successStories-panel figcaption h4 {
    color: #ffffff;
    margin-bottom: 1.25rem;
}
.successStories-panel figcaption p {
    color: #ffffff;
    font-size: 1rem;
    line-height: 1.4;
}
@media (max-width: 539px) {
    .successStories-panel figcaption p {
        font-size: 16px;
    }
}

.service-block {
    padding: 116px 0px;
}
.service-block .service-block-right {
    padding-left: 80px;
    max-width: 450px;
}
@media (max-width: 799px) {
    .service-block .service-block-right {
        padding-left: 50px;
    }
}
@media (max-width: 767.5px) {
    .service-block .service-block-right {
        padding-left: 0px;
    }
}
.service-block h2 {
    color: #ff008b;
    margin-bottom: 42px;
}
.service-block p {
    font-size: 20px;
    line-height: 36px;
    margin-bottom: 44px;
}
.service-block .image-column {
    min-height: 100%;
    margin-left: -20px;
}
@media (max-width: 1159px) {
    .service-block .image-column {
        margin-left: -80px;
    }
}
@media (max-width: 767.5px) {
    .service-block .image-column {
        min-height: 550px;
        margin-bottom: 120px;
        margin-left: -35px;
    }
}

.signingUp-panel {
    display: flex;
}
@media (max-width: 539px) {
    .signingUp-panel {
        flex-wrap: wrap;
    }
}
.signingUp-panel .form-control {
    width: calc(100% - 140px);
    margin-right: 16px;
}
@media (max-width: 539px) {
    .signingUp-panel .form-control {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 15px;
    }
}
@media (max-width: 539px) {
    .signingUp-panel .btn-outline-danger {
        width: 100%;
    }
}

.specialist-block {
    padding-top: 115px;
    padding-bottom: 130px;
}
.specialist-block .row {
    margin-bottom: 100px;
}
.specialist-block .row:last-child {
    margin-bottom: 0px;
}
.specialist-block figure {
    margin: 0;
}
@media (max-width: 1159px) {
    .specialist-block figure.lft {
        margin-right: -80px;
        margin-left: 40px;
    }
    .specialist-block figure.rght {
        margin-left: -80px;
        margin-right: 40px;
    }
}
@media (max-width: 767.5px) {
    .specialist-block figure.lft {
        margin-right: -35px;
        margin-left: -35px;
        margin-top: 45px;
    }
    .specialist-block figure.rght {
        margin-right: -35px;
        margin-left: -35px;
        margin-top: 45px;
    }
}
.specialist-block figure img {
    width: 100%;
}
.specialist-block .w-325 {
    max-width: 325px;
    width: 100%;
}

.cases-block .mt-less-135 {
    position: relative;
    margin-top: -135px;
}
.cases-block.cases-block-style2 {
    padding-top: 75px;
    padding-bottom: 10px;
}

.case-panel {
    margin-bottom: 68px;
}
@media (max-width: 500px) {
    .case-panel {
        margin-bottom: 6rem;
    }
}
.case-panel figure {
    margin: 0px;
}
.case-panel figure img {
    width: 100%;
}
.case-panel a {
    text-decoration: none;
}
.case-panel h3 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    word-break: break-word;
    color: #000;
    &:hover{
        color: #000 !important;
    }
}
.case-panel p {
    font-size: 1.2rem;
    line-height: calc(1rem * 1.4);
    color: #484848;
}
@media (max-width: 991.5px) {
    .case-panel p br {
        display: none;
    }
}



.workTogether-block {
    padding-top: 82px;
    padding-bottom: 82px;
}
.workTogether-block h2 {
    margin-bottom: 42px;
}
.workTogether-block p {
    font-size: 24px;
    font-weight: 400;
    line-height: 42px;
    margin-bottom: 42px;
}
@media (max-width: 991.5px) {
    .workTogether-block p {
        font-size: 22px;
        line-height: 38px;
    }
}
@media (max-width: 767.5px) {
    .workTogether-block p {
        font-size: 18px;
        line-height: 34px;
    }
}

.job-page-block {
    padding: 80px 0;
}
.job-page-block.jobdetails-page-block {
    padding-top: 0px;
}
.job-page-block.jobdetails-page-block .mt-less-212 {
    position: relative;
    margin-top: -212px;
}
@media (max-width: 991.5px) {
    .job-page-block.jobdetails-page-block .mt-less-212 {
        margin-top: -20%;
    }
}
.job-page-block.jobdetails-page-block .jobdetails-page-block-top {
    margin-bottom: 105px;
}
.job-page-block.jobdetails-page-block p {
    font-weight: 300;
}
.job-page-block .row {
    margin-bottom: 75px;
}
.job-page-block .row:last-child {
    margin-bottom: 0px;
}
@media (max-width: 991.5px) {
    .job-page-block h3 {
        margin-bottom: 45px;
    }
}
@media (max-width: 991.5px) {
    .job-page-block h2 {
        margin-bottom: 45px;
    }
}
.job-page-block p {
    font-size: 20px;
    line-height: 36px;
    max-width: 410px;
    margin-bottom: 35px;
}
@media (max-width: 991.5px) {
    .job-page-block p {
        font-size: 18px;
        line-height: 34px;
    }
}
@media (max-width: 767.5px) {
    .job-page-block p {
        font-size: 16px;
        line-height: 30px;
    }
}
.job-page-block .btn, .job-page-block .arrow-list ul {
    margin-bottom: 35px;
}

.contact-block {
    padding-bottom: 115px;
}
.contact-block .mt-less-135 {
    position: relative;
    margin-top: -135px;
}
.contact-block .column-left, .contact-block .column-right {
    max-width: 46%;
}
@media (max-width: 1023px) {
    .contact-block .column-left, .contact-block .column-right {
        max-width: 50%;
    }
}
@media (max-width: 767.5px) {
    .contact-block .column-left, .contact-block .column-right {
        max-width: 100%;
    }
}
.contact-block .map {
    margin-bottom: 118px;
}
@media (max-width: 767.5px) {
    .contact-block .map {
        margin-bottom: 42px;
    }
}
.contact-block .contact-form-top {
    margin-bottom: 118px;
}
.contact-block .contact-form-top img {
    width: 100%;
}
.contact-block h3 {
    margin-bottom: 12px;
}
.contact-block p {
    font-size: 20px;
    font-weight: 300;
    line-height: 36px;
}
@media (max-width: 991.5px) {
    .contact-block p {
        font-size: 18px;
        line-height: 34px;
    }
}
@media (max-width: 767.5px) {
    .contact-block .address-panel {
        margin-bottom: 118px;
    }
}

.address-panel h3 {
    color: #000;
}
.address-panel address {
    margin-bottom: 46px;
    color: #000000;
    font-size: 20px;
    font-weight: 300;
    line-height: 36px;
}
@media (max-width: 991.5px) {
    .address-panel address {
        font-size: 18px;
        line-height: 34px;
    }
}
.address-panel address a {
    color: #000000;
    text-decoration: none;
}

.insightpage-block {
    padding-bottom: 108px;
}
.insightpage-block .mt-less-212 {
    position: relative;
    margin-top: -212px;
}
@media (max-width: 991.5px) {
    .insightpage-block .mt-less-212 {
        margin-top: -20%;
    }
}
.insightpage-block .insight-block-top {
    margin-bottom: 105px;
}
.insightpage-block .insight-block-top figure img {
    width: 100%;
}
.insightpage-block h2 {
    padding-top: 10px;
}
@media (max-width: 991.5px) {
    .insightpage-block h2 {
        margin-bottom: 25px;
    }
}
.insightpage-block p {
    font-size: 20px;
    font-weight: 300;
    line-height: 36px;
    max-width: 410px;
    margin-bottom: 36px;
}
@media (max-width: 991.5px) {
    .insightpage-block p {
        font-size: 18px;
        line-height: 34px;
    }
}
@media (max-width: 767.5px) {
    .insightpage-block p {
        font-size: 16px;
        line-height: 30px;
    }
}

.story-block.mb-115 {
    margin-bottom: 115px;
}
.story-block .black-bg {
    padding-top: 112px;
    padding-bottom: 82px;
}
.story-block h2, .story-block p {
    color: #ffffff;
}
.story-block h2 {
    padding-top: 10px;
}
@media (max-width: 991.5px) {
    .story-block h2 {
        margin-bottom: 25px;
    }
}
.story-block p {
    font-size: 20px;
    line-height: 36px;
    font-weight: 300;
    max-width: 410px;
}
@media (max-width: 991.5px) {
    .story-block p {
        font-size: 18px;
        line-height: 34px;
    }
}
@media (max-width: 767.5px) {
    .story-block p {
        font-size: 16px;
        line-height: 30px;
    }
}
.story-block .about-banner-slider {
    margin-top: 75px;
}

.text-block {
    padding-top: 285px;
    padding-bottom: 106px;
}
.text-block h1 {
    color: #ffffff;
    padding-bottom: 30px;
}
.text-block .text-p {
    font-size: 20px;
    line-height: 36px;
    color: #ffffff;
}
@media (max-width: 991.5px) {
    .text-block .text-p {
        font-size: 18px;
        line-height: 32px;
    }
}
@media (max-width: 639px) {
    .text-block .text-p {
        font-size: 16px;
        line-height: 28px;
    }
}
.text-block .text-p a {
    color: #ffffff;
    text-decoration: none;
}

.apply-block .column-right {
    max-width: 62.5%;
}
@media (max-width: 1159px) {
    .apply-block .column-right {
        padding-right: 80px;
    }
}
@media (max-width: 767.5px) {
    .apply-block .column-right {
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x) * 0.5);
    }
}
.apply-block .column-right .column-right-inner {
    float: right;
    max-width: 665px;
    width: 100%;
    padding-top: 102px;
    padding-bottom: 162px;
}
@media (max-width: 767.5px) {
    .apply-block .column-right .column-right-inner {
        max-width: 100%;
        float: none;
    }
}
.apply-block .column-left {
    padding-right: 0px;
    max-width: 37.5%;
}
@media (max-width: 767.5px) {
    .apply-block .column-left {
        max-width: 100%;
    }
}
.apply-block .column-left > div {
    height: 20%;
}
@media (max-width: 1159px) {
    .apply-block .column-left > div {
        margin-right: -80px;
    }
}
@media (max-width: 767.5px) {
    .apply-block .column-left > div {
        height: 130px;
        margin-left: -35px;
        margin-right: -35px;
    }
    .apply-block .column-left > div.header-all-img {
        display: none;
    }
    .apply-block .column-left > div.header-logo img {
        max-width: 260px;
    }
}
.apply-block h1 {
    margin-bottom: 42px;
}
.apply-block p {
    font-size: 20px;
    font-weight: 300;
    line-height: 36px;
    max-width: 500px;
    margin-bottom: 68px;
}
@media (max-width: 991.5px) {
    .apply-block p {
        font-size: 18px;
        line-height: 34px;
    }
}
@media (max-width: 767.5px) {
    .apply-block p {
        font-size: 16px;
        line-height: 30px;
        max-width: 100%;
    }
}
.apply-block h3 {
    color: #0568fe;
    margin-bottom: 23px;
}
.apply-block h2 {
    margin-bottom: 42px;
}
.apply-block .mb-15 {
    margin-bottom: 15px;
}
.apply-block .mb-20 {
    margin-bottom: 20px;
}
.apply-block .mb-25 {
    margin-bottom: 25px;
}
.apply-block .mb-42 {
    margin-bottom: 42px;
}
.apply-block .mb-50 {
    margin-bottom: 50px;
}
.apply-block .mb-75 {
    margin-bottom: 75px;
}
.apply-block .mb-105 {
    margin-bottom: 105px;
}
.apply-block .header-logo {
    background: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.apply-block .header-all-img {
    overflow: hidden;
}
.apply-block .header-all-img img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.apply-block .preceded-block {
    padding-top: 75px;
    padding-bottom: 0;
}
.apply-block .preceded-block h3 {
    margin-bottom: 15px;
}
.apply-block .w-410 {
    max-width: 410px;
    width: 100%;
}
@media (max-width: 767.5px) {
    .apply-block .w-410 {
        max-width: 100%;
    }
}
.apply-block.yellow-bg .form-control {
    border: solid 1px #ffffff;
}
.apply-block.style-1 .column-right .column-right-inner {
    padding-bottom: 80px;
}
.apply-block.style-1 .column-left > div {
    height: 11.1111111111%;
}
.apply-block.style-1 .column-left > div.header-logo {
    height: 197px;
}

.thankyou-page {
    padding-top: 75px;
    padding-bottom: 75px;
    height: 100vh;
}
.thankyou-page h1, .thankyou-page p {
    color: #ffffff;
}
.thankyou-page h1 {
    padding-top: 6px;
    margin-bottom: 15px;
}
.thankyou-page p {
    font-size: 24px;
    font-weight: 400;
    line-height: 42px;
    margin-bottom: 32px;
}
.thankyou-page .top-logo {
    margin-bottom: 10px;
}
@media (max-width: 767.5px) {
    .thankyou-page .top-logo {
        margin-bottom: 25px;
    }
}

.textimage-block {
    padding-top: 115px;
    padding-bottom: 110px;
}
.textimage-block h2 {
    margin-bottom: 44px;
}
.textimage-block p {
    font-size: 20px;
    font-weight: 300;
    line-height: 36px;
    margin-bottom: 35px;
}
@media (max-width: 991.5px) {
    .textimage-block p {
        font-size: 18px;
        line-height: 34px;
    }
}
@media (max-width: 767.5px) {
    .textimage-block p {
        font-size: 16px;
        line-height: 30px;
    }
}
.textimage-block .image {
    min-height: 100%;
}
.textimage-block.style-1 .image {
    margin-right: -15px;
    position: relative;
}
@media (max-width: 1159px) {
    .textimage-block.style-1 .image {
        margin-right: -80px;
    }
}
@media (max-width: 767.5px) {
    .textimage-block.style-1 .image {
        height: 75vh;
        margin-left: -35px;
        margin-right: -35px;
        margin-bottom: 50px;
        min-height: inherit;
    }
}
.textimage-block.style-2 .image {
    margin-left: -15px;
    position: relative;
    max-width: calc(100% - 65px);
}
@media (max-width: 1159px) {
    .textimage-block.style-2 .image {
        margin-left: -80px;
        max-width: calc(100% + 30px);
    }
}
@media (max-width: 767.5px) {
    .textimage-block.style-2 .image {
        margin-left: -35px;
        max-width: calc(100% + 70px);
        height: 75vh;
        margin-right: -35px;
        min-height: inherit;
        margin-bottom: 52px;
    }
}
.textimage-block.style-2 h2 {
    margin: 65px 0px 42px;
}
.textimage-block.style-3 .image {
    margin-left: -15px;
    position: relative;
    max-width: calc(100% - 65px);
    min-height: calc(100% - 85px);
}
@media (max-width: 1159px) {
    .textimage-block.style-3 .image {
        margin-left: -80px;
        max-width: calc(100% + 40px);
    }
}
@media (max-width: 767.5px) {
    .textimage-block.style-3 .image {
        height: 500px;
        margin-left: -35px;
        margin-right: -35px;
        max-width: calc(100% + 70px);
        margin-bottom: 52px;
    }
}
.textimage-block.style-3 h3 {
    color: #0568fe;
    margin-bottom: 42px;
}
.textimage-block .column {
    max-width: 490px;
}
.textimage-block .column > *:last-child {
    margin-bottom: 0px;
}
.textimage-block .column.column-left {
    float: right;
    padding-right: 80px;
}
@media (max-width: 767px) {
    .textimage-block .column.column-left {
        padding-right: 0px;
        float: none;
    }
}
.textimage-block .column.column-right {
    padding-right: 90px;
}
@media (max-width: 991.5px) {
    .textimage-block .column.column-right {
        padding-right: 0px;
    }
}

.textimage2-block {
    padding: 110px 0px;
}
.textimage2-block p {
    font-size: 20px;
    font-weight: 300;
    line-height: 36px;
}
@media (max-width: 991.5px) {
    .textimage2-block p {
        font-size: 18px;
        line-height: 34px;
    }
}
@media (max-width: 767.5px) {
    .textimage2-block p {
        font-size: 16px;
        line-height: 30px;
    }
}
.textimage2-block figure {
    margin-right: -85px;
    margin-top: 50px;
    margin-bottom: 154px;
}
.textimage2-block .panel.panel-1 {
    max-width: 580px;
}
.textimage2-block .panel.panel-1 p {
    margin-bottom: 42px;
}
.textimage2-block .panel.panel-2 {
    max-width: 665px;
    margin: 0 auto;
    margin-bottom: 68px;
}
.textimage2-block .panel.panel-3 {
    max-width: 665px;
    margin: 0 auto;
    margin-bottom: 115px;
}
.textimage2-block .panel.panel-3 h2 {
    margin-bottom: 42px;
}
.textimage2-block .panel.panel-3 p {
    margin-bottom: 42px;
}

.textimage3-block {
    padding-bottom: 100px;
}
.textimage3-block p {
    font-size: 20px;
    font-weight: 300;
    line-height: 36px;
}
@media (max-width: 991.5px) {
    .textimage3-block p {
        font-size: 18px;
        line-height: 34px;
    }
}
@media (max-width: 767.5px) {
    .textimage3-block p {
        font-size: 16px;
        line-height: 30px;
    }
}
.textimage3-block .panel.panel-1 {
    padding-top: 85px;
    padding-bottom: 92px;
}
.textimage3-block .panel.panel-1 p, .textimage3-block .panel.panel-1 h2 {
    max-width: 400px;
}
.textimage3-block .panel.panel-1 h2 {
    margin-bottom: 42px;
}
.textimage3-block .panel.panel-2 {
    margin-bottom: 160px;
}
.textimage3-block .panel.panel-2 .image {
    max-width: 50%;
    width: 100%;
    margin-left: 17%;
    position: relative;
    margin-top: -65px;
}
@media (max-width: 991.5px) {
    .textimage3-block .panel.panel-2 .image {
        margin-left: 0%;
        max-width: 60%;
    }
}
@media (max-width: 767.5px) {
    .textimage3-block .panel.panel-2 .image {
        max-width: inherit;
        width: calc(100% + 35px);
        margin-left: -35px;
    }
}
@media (max-width: 991.5px) {
    .textimage3-block .panel.panel-2 .image img {
        height: 520px;
        width: 100%;
        object-fit: cover;
    }
}
.textimage3-block .panel.panel-2 .strategy-panel {
    max-width: 48%;
    margin-left: -15%;
    position: relative;
    right: -30px;
    margin-top: 50px;
}
@media (max-width: 991.5px) {
    .textimage3-block .panel.panel-2 .strategy-panel {
        max-width: 65%;
        margin-left: -25%;
    }
}
@media (max-width: 767.5px) {
    .textimage3-block .panel.panel-2 .strategy-panel {
        max-width: inherit;
        width: calc(100% + 35px);
        margin-left: 0%;
        right: 0px;
        margin-top: -50px;
    }
}
.textimage3-block .panel.panel-2 .strategy-panel .squareblue2-check-list {
    margin-bottom: 68px;
}
.textimage3-block .panel.panel-2 .strategy-panel .squareblue2-check-list ul li {
    margin-bottom: 0px;
}
.textimage3-block .panel.panel-3 {
    max-width: 665px;
    margin: 0 auto;
}
.textimage3-block .panel.panel-3 h3 {
    color: #0568fe;
    margin-bottom: 42px;
}
.textimage3-block .panel.panel-3 p {
    margin-bottom: 38px;
}

.b2btext-block {
    padding-top: 82px;
    padding-bottom: 58px;
}
.b2btext-block h3 {
    color: #ffec00;
    margin-bottom: 40px;
}
.b2btext-block p {
    font-size: 20px;
    font-weight: 300;
    line-height: 36px;
    color: #ffffff;
    max-width: 665px;
}
@media (max-width: 991.5px) {
    .b2btext-block p {
        font-size: 18px;
        line-height: 34px;
    }
}
@media (max-width: 767.5px) {
    .b2btext-block p {
        font-size: 16px;
        line-height: 30px;
    }
}

.crazyproject-block {
    padding: 190px 0px 45px;
    margin: 70px 0;
    position: relative;
}
.crazyproject-block video {
    position: absolute;
    top: 0;
    right: 0;
    content: "";
    width: 73.5%;
    height: 100%;
    object-fit: cover;
}
@media (max-width: 767.5px) {
    .crazyproject-block:after {
        width: 100%;
        margin-left: 0 !important;
    }
}
.crazyproject-block .crazyproject-panel {
    margin-left: 8.5%;
    position: relative;
    z-index: 2;
}
@media (max-width: 767.5px) {
    .crazyproject-block .crazyproject-panel {
        margin-left: 0 !important;
        width: 100%;
    }
}

.crazyproject-panel {
    max-width: 442px;
    width: 100%;
    padding: 45px 42px 100px;
    background: #000000;
}
.crazyproject-panel h2, .crazyproject-panel p {
    color: #ffffff;
}
.crazyproject-panel h2 {
    margin-bottom: 15px;
}
.crazyproject-panel p {
    font-size: 24px;
    font-weight: 400;
    line-height: 42px;
    margin-bottom: 28px;
}
.crazyproject-panel .btn {
    margin-right: 26px;
}
@media (max-width: 767.5px) {
    .crazyproject-panel .btn {
        margin-right: 15px;
    }
}

.strategy-panel {
    padding: 72px 42px 92px;
    background: #0568fe;
}
.strategy-panel .squareblue2-check-list ul li {
    color: #ffffff;
}
.strategy-panel h2, .strategy-panel p {
    color: #ffffff;
}
.strategy-panel h2 {
    color: #00ede8;
    margin-bottom: 42px;
}
.strategy-panel p {
    font-size: 20px;
    font-weight: 300;
    line-height: 36px;
}

.faq-block {
    padding-top: 78px;
    padding-bottom: 100px;
}
.faq-block .faq-block-title {
    border-bottom: 2px solid #000000;
    padding-bottom: 32px;
}

.accordion .accordion-button {
    border: 0;
    box-shadow: inherit;
    background: transparent;
    padding-left: 0px;
    padding-right: 0px;
}
.accordion .accordion-button h3 {
    margin-bottom: 0px;
    color: #0568fe;
}
.accordion .accordion-body {
    color: #000000;
    font-size: 20px;
    font-weight: 300;
    line-height: 36px;
    padding: 0px 0px;
}
@media (max-width: 991.5px) {
    .accordion .accordion-body {
        font-size: 18px;
        line-height: 34px;
    }
}
@media (max-width: 767.5px) {
    .accordion .accordion-body {
        font-size: 16px;
        line-height: 30px;
    }
}
.accordion .accordion-item {
    padding: 0 0 1rem 0;
    border: 0;
}
.accordion .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.accordion .accordion-item:last-of-type {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}
.accordion .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.lpBanner {
    position: relative;
    padding-top: 82px;
    padding-bottom: 82px;
}
.lpBanner::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, 0.8);
}
.lpBanner .container {
    position: relative;
    z-index: 1;
}
.lpBanner .column-top {
    position: relative;
    padding-top: 70px;
}
.lpBanner .column-top-left {
    max-width: 60%;
}
@media (max-width: 991.5px) {
    .lpBanner .column-top-left {
        max-width: 100%;
    }
}
.lpBanner .column-top-right {
    max-width: 40%;
}
@media (max-width: 991.5px) {
    .lpBanner .column-top-right {
        max-width: 100%;
    }
}
.lpBanner .column-left {
    max-width: 410px;
}
.lpBanner .column-right {
    max-width: 410px;
    float: right;
    margin-top: -12px;
}
@media (max-width: 991.5px) {
    .lpBanner .column-right {
        margin-top: 120px;
        max-width: 100%;
        float: none;
    }
}
.lpBanner .about-banner-slider {
    margin-top: 112px;
}
.lpBanner h1, .lpBanner p, .lpBanner .check-list ul li {
    color: #ffffff;
}
.lpBanner h1 {
    margin-bottom: 40px;
}
.lpBanner h3 {
    color: #ffec00;
    margin-bottom: 24px;
}
.lpBanner p {
    font-size: 24px;
    font-weight: 400;
    line-height: 42px;
    max-width: 520px;
    margin-bottom: 68px;
}
@media (max-width: 991.5px) {
    .lpBanner p {
        font-size: 18px;
        line-height: 34px;
    }
}
@media (max-width: 767.5px) {
    .lpBanner p {
        font-size: 16px;
        line-height: 30px;
        max-width: 100%;
    }
}
.lpBanner .rounded-img {
    position: absolute;
    top: -112px;
    right: -125px;
    display: inline-block;
}
@media (max-width: 1023px) {
    .lpBanner .rounded-img {
        right: -210px;
    }
}
@media (max-width: 991.5px) {
    .lpBanner .rounded-img {
        position: relative;
        top: 0;
        right: -50px;
        margin-bottom: 100px;
        float: right;
    }
}
@media (max-width: 767.5px) {
    .lpBanner .rounded-img {
        right: -200px;
    }
}
.lpBanner .rounded-img img {
    width: 450px;
    height: 450px;
    border-radius: 100%;
    display: block;
    max-width: inherit;
}
.lpBanner .rounded-img .percentage {
    width: 260px;
    height: 260px;
    transform: rotate(10deg);
    background: #ffec00;
    border-radius: 100%;
    text-align: center;
    position: absolute;
    bottom: 48px;
    left: -83px;
    padding: 45px 25px;
}
.lpBanner .rounded-img .percentage strong {
    font-size: 60px;
    font-weight: 700;
    display: block;
    line-height: 50px;
    margin-bottom: 3px;
}
.lpBanner .rounded-img .percentage span {
    display: block;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
}
.lpBanner.lpBanner-footer {
    padding-bottom: 100px;
    margin-bottom: 65px;
}
.lpBanner.lpBanner-footer h2 {
    color: #ffffff;
}
.lpBanner.lpBanner-footer .column-top-right {
    max-width: 50%;
}
@media (max-width: 991.5px) {
    .lpBanner.lpBanner-footer .column-top-right {
        max-width: 100%;
    }
}
.lpBanner.lpBanner-footer .column-top-left {
    max-width: 50%;
}
@media (max-width: 991.5px) {
    .lpBanner.lpBanner-footer .column-top-left {
        max-width: 100%;
    }
}
.lpBanner.lpBanner-footer .column-top-left h2 {
    color: #ffffff;
}
.lpBanner.lpBanner-footer .column-top {
    padding-top: 0px;
}
.lpBanner.lpBanner-footer .column-right {
    float: none;
    margin-top: 126px;
}
@media (max-width: 991.5px) {
    .lpBanner.lpBanner-footer .column-right {
        margin-top: 60px;
    }
}
.lpBanner.lpBanner-footer .rounded-img {
    right: inherit;
    left: -137px;
    top: -182px;
}
@media (max-width: 1023px) {
    .lpBanner.lpBanner-footer .rounded-img {
        left: -160px;
    }
}
@media (max-width: 991.5px) {
    .lpBanner.lpBanner-footer .rounded-img {
        left: -210px;
        top: 0;
        margin-top: -210px;
    }
}
@media (max-width: 767.5px) {
    .lpBanner.lpBanner-footer .rounded-img {
        left: -105px;
    }
}
.lpBanner.lpBanner-footer .rounded-img .percentage {
    left: 100%;
    margin-left: -143px;
    position: absolute;
    bottom: 30px;
    transform: rotate(3deg);
    padding: 52px 25px;
}
.lpBanner.lpBanner-footer .lpfooterlogo {
    margin-top: 60px;
    display: inline-block;
}
@media (max-width: 767.5px) {
    .lpBanner.lpBanner-footer .lpfooterlogo img {
        max-width: 230px;
    }
}
@media (max-width: 991.5px) {
    .lpBanner.lpBanner-footer .check-list {
        margin-bottom: 52px;
    }
}
.lpBanner.lpBanner-footer p {
    margin-bottom: 38px;
}
@media (max-width: 991.5px) {
    .lpBanner.lpBanner-footer p {
        margin-bottom: 0px;
    }
}

.preceded-block {
    padding-top: 25px;
    padding-bottom: 95px;
}
.preceded-block h3 {
    color: #0568fe;
    margin-bottom: 50px;
}
.preceded-block img {
    margin-bottom: 22px;
}

.management-block {
    padding: 82px 0px;
    background: #9f06fe;
}
.management-block.mb-270 {
    margin-bottom: 270px;
}
.management-block h3 {
    color: #ffec00;
    max-width: 665px;
    margin: 0 auto;
    margin-bottom: 48px;
}
.management-block .author-panel strong, .management-block .author-panel span {
    text-align: left;
    color: #ffffff;
}

/* your-challeng */
.your-challenge-div {
    position: absolute;
    right: -400px;
    width: 400px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease-out;
}
.your-challenge-div.active {
    right: 0;
}
.your-challenge-div .inner {
    background: #9f06fe;
    padding: 50px 90px 20px 30px;
    position: relative;
    z-index: 1;
}
.your-challenge-div .clsbtn {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 9;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.your-challenge-div .clsbtn:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 10px;
    background: #ffffff;
    transform: rotate(45deg);
}
.your-challenge-div .clsbtn:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 10px;
    background: #ffffff;
    transform: rotate(-45deg);
}
.your-challenge-div h3 {
    color: #ffffff;
    margin-bottom: 20px;
}
.your-challenge-div ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
}
.your-challenge-div ul li {
    padding-left: 15px;
    margin: 0px;
    margin-bottom: 40px;
    color: #ffffff;
    position: relative;
}
.your-challenge-div ul li:last-child {
    margin-bottom: 0px;
}
.your-challenge-div ul li:after {
    position: absolute;
    top: 5px;
    left: 0px;
    width: 2px;
    height: calc(100% - 9px);
    content: "";
    background: #fff;
}
.your-challenge-div .btn-your-challenge {
    position: absolute;
    top: 0;
    margin-top: 230px;
    transform: rotate(90deg);
    border-radius: 0 0 5px 5px;
    left: -163px;
}

/*# sourceMappingURL=style.static.map */
