section.faq {
    @media (max-width: $breakpoint-md) {
        h3.h1 {
            font-size: 2rem;
        }
    }

    .accordion-item {
        .accordion-header {
            padding-top: 10px;
            border-top: 2px solid $gray-400 !important;
            button::after {
                content: "\f107" !important;
                font-family: 'Font Awesome 5 Pro' !important;
                font-weight: 500 !important;
                display: block !important;
                position: absolute !important;
                right: 0;
                top: 1.4rem;
                background: none !important;
                margin-right: 1.5rem;

            }
            button:not(.collapsed)::after {
                color: $neon !important;
            }
        }

        .accordion-button {
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
            background: none !important;
            font-weight: 300 !important;
            font-size: 1.5rem;
            color: $gray-900 !important;
            padding-right: 3.5rem !important;

            @media (max-width: $breakpoint-md) {
                font-size: 1.1rem !important;
            }

        }
    }

    .accordion-body {
        p, li, span, a {
            color: $gray-600 !important;
            @media (max-width: $breakpoint-md) {
                font-size: 1rem !important;
            }
        }

        h1, h2, h3, h4, h5, h6 {

            @media (max-width: $breakpoint-md) {
                font-size: 80%;
            }
        }
    }
}